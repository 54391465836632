import i18n from "@src/translations/i18n";
import { APP_STAGE } from "./config";

export const IS_DEV_MODE = APP_STAGE === "dev";
export const APP_LANG = i18n.language;

export const URL_REGEX =
  /^https?:\/\/([A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]|%[0-9A-Fa-f]{2})*([A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]|%[0-9A-Fa-f]{2}|(\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}))(?::\d+)?(?:\/[^\s]*)?$/;

export const AUTH_CODE_HEADER = "AUTH-CODE";
export const AUTH_CODE_QUERY = "auth_code";

export enum STORAGE_AUTH_KEYS {
  CODE = "auth_code",
  USER = "user"
}

export const STORAGE_APP_UPDATED = "app_updated";
