import React from "react";
import { useTranslation } from "react-i18next";

import { reloadApp } from "@src/utils/reloadApp";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import "./ErrorBoundaryFallback.scss";

const ErrorBoundaryFallback = () => {
  const { t } = useTranslation(["layout", "common"]);

  return (
    <BaseLayout withImage disableHelment>
      <div className="error-bounadry-fallback">
        <Stack rowGap={32} alignItems="center">
          <p className="p0 error text-center">{t("layout:errorBoundaryFallback.title")}</p>

          <img src="/images/status/icon-error-2.svg" alt="invalid auth url" />

          <Button size="small" fullWidth onClick={reloadApp}>
            {t("common:tryAgain")}
          </Button>
        </Stack>
      </div>
    </BaseLayout>
  );
};

export default ErrorBoundaryFallback;
