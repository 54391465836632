import React from "react";
import { Navigate } from "react-router-dom";

import { IChildren } from "@src/types/IChildren.types";
import { IS_DEV_MODE } from "@src/config/helpers";
import { PATHS } from "src/router/paths";

const DevModeGuard = ({ children }: IChildren) => {
  if (IS_DEV_MODE) return <>{children}</>;

  return <Navigate to={PATHS.main.path} />;
};

export default DevModeGuard;
