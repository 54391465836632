import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";

import "./CertsEmptyState.scss";

const CertsEmptyState = () => {
  const { t } = useTranslation("app");

  return (
    <Stack rowGap={32} alignItems="center" justifyContent="center" fullSize className="certs-empty-state">
      <img src="/images/last-certs-empty.svg" alt="certs empty state" width={36} height={40} />

      <div className="text-center">
        <p className="p0 gray-0">{t("dashboard.certsEmptyState.info")}</p>
        <p className="p0 gray-0">{t("dashboard.certsEmptyState.hint")}</p>
      </div>
    </Stack>
  );
};

export default CertsEmptyState;
