import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import { GLOBAL_ABORT_TIMEOUT } from "@src/config/config";
import { AUTH_CODE_HEADER, STORAGE_AUTH_KEYS } from "@src/config/helpers";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";

export interface ApiClientOptions {
  baseUrl: string;
}

export class ApiClient {
  private axios: AxiosInstance;

  constructor(private options: ApiClientOptions) {
    this.axios = this.createAxiosInstance();
  }

  private createAxiosInstance() {
    const instance = axios.create({
      baseURL: this.options.baseUrl,
      headers: {
        "Content-Type": "application/json"
      }
    });

    // instance.interceptors.request.use((config) => {
    //   const authCode = BrowserStorageManager.readLocalStorage<string>(STORAGE_AUTH_KEYS.CODE);
    //   if (authCode) config.headers[AUTH_CODE_HEADER] = authCode;
    //   return config;
    // });

    return instance;
  }

  async sendRequest<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), GLOBAL_ABORT_TIMEOUT);

    const authCode = BrowserStorageManager.readLocalStorage<string>(STORAGE_AUTH_KEYS.CODE);

    const response = await this.axios.request<T>({
      ...config,
      signal: controller.signal,
      headers: { [AUTH_CODE_HEADER]: authCode, ...config.headers }
    });

    clearTimeout(timeoutId);
    return response;
  }
}
