import React from "react";

import { ProductDto } from "@src/services/api/api.dtos";
import { getImage } from "@src/services/api/methods";

import ClickableCard from "@src/components/common/ClickableCard/ClickableCard";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";

import "./BaseProductCard.scss";

interface BaseProductCardProps {
  product: ProductDto;
  onClick?(): void;
}

const BaseProductCard = ({ product, onClick }: BaseProductCardProps) => {
  const { image, title, code } = product;

  const cardDisabled = !onClick;

  return (
    <ClickableCard padding="small" disabled={cardDisabled} onClick={onClick} className="base-product-card">
      <Group colGap={12} fullWidth alignItems="flex-start">
        <img src={image && getImage(image)} alt={title || "product image"} width={96} height={96} />

        <Stack rowGap={10} className="mt-1">
          <p className="p0 medium base-product-card-title">{title}</p>
          <p className="p0 regular">{code}</p>
        </Stack>
      </Group>
    </ClickableCard>
  );
};

export default BaseProductCard;
