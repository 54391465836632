import React from "react";

import { IChildren } from "@src/types/IChildren.types";

import DesktopView from "./DesktopView/DesktopView";
import { DISABLE_DESKTOP_DEVICE } from "@src/config/config";
import { deviceInfo } from "@src/utils/browser/deviceInfo";

const DesktopDeviceGuard = ({ children }: IChildren) => {
  if (deviceInfo.isDesktopDevice() && DISABLE_DESKTOP_DEVICE) {
    return <DesktopView />;
  }

  return <>{children}</>;
};

export default DesktopDeviceGuard;
