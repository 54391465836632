export type PathFunction = (id: string) => string;

type PathArguments = {
  path: string;
  pathName?: string;
  tKey?: string;
  ref?: PathFunction;
};

export class Path {
  readonly path: string;
  readonly pathName?: string;
  readonly tKey?: string;
  private ref?: PathFunction;

  constructor({ path, pathName, tKey, ref }: PathArguments) {
    this.path = path;
    this.pathName = pathName;
    this.tKey = tKey;
    this.ref = ref;
  }

  getPath(param: string) {
    return this.ref ? this.ref(param) : this.path;
  }
}
