import React from "react";
import { useTranslation } from "react-i18next";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Spinner from "../Spinner/Spinner";
import Dots from "../Dots/Dots";

import "./LoadingScreen.scss";

const LoadingScreen = () => {
  const { t } = useTranslation("common");

  return (
    <BaseLayout withImage>
      <div className="loading-screen">
        <Stack rowGap={16} alignItems="center">
          <p className="p0 relative">
            {t("loading")} <Dots />
          </p>
          <Spinner sizePx={28} />
        </Stack>
      </div>
    </BaseLayout>
  );
};

export default LoadingScreen;
