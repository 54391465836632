import { CertStatus, CertStatusType } from "@src/services/api/api.dtos";

export const formatCertStatus = (status?: CertStatusType) => {
  switch (status) {
    case CertStatus.in_progress:
      return "common:certStatus.inProgress";
    case CertStatus.minted:
      return "common:certStatus.minted";
    case CertStatus.error:
      return "common:certStatus.error";
    default:
      return "common:certStatus.inProgress";
  }
};
