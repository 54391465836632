import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAuth } from "@src/contexts/AuthContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Stack from "@src/components/arrangement/Stack/Stack";
import Input from "@src/components/dataEntry/Input/Input";
import Button from "@src/components/dataEntry/Button/Button";
import Spinner from "@src/components/common/Spinner/Spinner";

import "./AuthAccessCode.scss";

const AuthAccessCode = () => {
  const [authCode, setAuthCode] = useState<string | null>(null);

  const { manualAuthorize, authStatus, authError } = useAuth();

  const { t } = useTranslation(["auth", "apiResponse", "common"]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAuthCode(value);
  };

  const handleAuthorize = () => {
    if (!authCode) return;
    manualAuthorize(authCode);
  };

  const authErrorMessage =
    authError?.response?.status === 401 ? "apiResponse:errors.invalidAuthCode" : "apiResponse:errors.base";

  const loading = useMemo(() => authStatus === "logging", [authStatus]);

  return (
    <div className="auth-access-code">
      <Stack rowGap={32} alignItems="center" className="card-white">
        <Input name="accessCode" placeholder={t("auth:authAccessCode.input.placeholder")} onChange={handleChange} />

        {loading && <Spinner />}

        {authError && (
          <Stack rowGap={16} alignItems="center">
            <p className="p0 error">{t(authErrorMessage as any)}</p>
            <img src="/images/status/icon-error-2.svg" alt="error" />
          </Stack>
        )}

        <Button onClick={handleAuthorize} disabled={loading}>
          {t("common:confirm")}
        </Button>
      </Stack>

      <div className="auth-access-code-info">
        <p className="p0 gray-0">{t("auth:authCodeInfo")}</p>
      </div>
    </div>
  );
};

export default (
  <LayoutWithNavbar title="auth:authAccessCode.navbar">
    <AuthAccessCode />
  </LayoutWithNavbar>
);
