import { CertDto } from "@src/services/api/api.dtos";
import { modalsContextFactory } from "./ModalsContextFactory";

interface DeleteCertModal {
  type: "deleteCert";
  data: CertDto;
}

interface WithoutObjectModal {
  type: "someModal";
  data?: null;
}

export type CertsModal = DeleteCertModal | WithoutObjectModal;

export const { ModalsProvider: CertsModalsProvider, useModalsContext: useCertsModals } =
  modalsContextFactory<CertsModal>();
