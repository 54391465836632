import React from "react";
import classNames from "classnames";

import { InputProps } from "./Input.types";
import "./Input.scss";

const Input = ({
  id,
  inputRef,
  name,
  value,
  placeholder,
  disabled,
  required,
  readOnly,
  type,
  autoComplete,
  onBlur,
  onChange,
  onFocus,
  onClick,
  className
}: InputProps) => {
  const classes = classNames("input", className);

  return (
    <input
      id={id}
      ref={inputRef}
      name={name}
      value={value}
      placeholder={placeholder}
      disabled={disabled}
      required={required}
      readOnly={readOnly}
      type={type}
      autoComplete={autoComplete}
      onBlur={onBlur}
      onChange={onChange}
      onFocus={onFocus}
      onClick={onClick}
      className={classes}
    />
  );
};

export default Input;
