import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@src/router/paths";
import { useCertify } from "@src/contexts/CertifyContext";
import { getImage } from "@src/services/api/methods";

import ClickableCard from "@src/components/common/ClickableCard/ClickableCard";
import Group from "@src/components/arrangement/Group/Group";
import Stack from "@src/components/arrangement/Stack/Stack";

import "./CertifyProductCard.scss";

const CertifyProductCard = () => {
  const navigate = useNavigate();
  const { product } = useCertify();

  const { t } = useTranslation("app");

  const navigateTo = (path: string) => () => navigate(path);

  const cardVariant = product ? "success" : "base";
  const imgHref = product ? getImage(product.image) : "/images/certify-search-product.png";
  const imgAlt = product ? product.title : "search product";

  return (
    <ClickableCard
      variant={cardVariant}
      padding="small"
      onClick={navigateTo(PATHS.certifyScanProduct.path)}
      className="certify-product-card"
    >
      <Group colGap={16} fullWidth alignItems={product ? "flex-start" : "center"}>
        <img src={imgHref} alt={imgAlt} width={96} height={96} />

        {product ? (
          <Stack rowGap={10} className="mt-1">
            <p className="p0 medium card-title">{product.title}</p>
            <p className="p0 regular">{product.code}</p>
          </Stack>
        ) : (
          <p className="p0 full-width">{t("certify.search")}</p>
        )}
      </Group>
    </ClickableCard>
  );
};

export default CertifyProductCard;
