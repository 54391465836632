import React from "react";

import { useCertsModals } from "@src/contexts/CertsModalsContext";
import DeleteCert from "./DeleteCert/DeleteCert";

const CertsModals = () => {
  const { activeModal } = useCertsModals();

  if (activeModal === null) return <></>;
  const { type, data } = activeModal;

  if (type === "deleteCert") return <DeleteCert cert={data} />;

  return <></>;
};

export default CertsModals;
