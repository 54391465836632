import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { CertDto, CertStatus } from "@src/services/api/api.dtos";
import { retryCertify } from "@src/services/api/methods";
import { sleep } from "@src/utils/sleep";

import { useToasts } from "@src/contexts/ToastsContext";
import { useCertsModals } from "@src/contexts/CertsModalsContext";
import { useAuth } from "@src/contexts/AuthContext";
import { useCertsData } from "@src/contexts/CertsDataContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";
import Dots from "@src/components/common/Dots/Dots";

import { formatCertStatus } from "../../utils/formatCertStatus";
import "./CertStatusCard.scss";

interface CertStatusCardProps {
  cert: CertDto;
}

const CertStatusCard = ({ cert }: CertStatusCardProps) => {
  const { t } = useTranslation(["app", "common", "apiResponse"]);

  const { updateCertStatus } = useCertsData();
  const { showToast, hideToast } = useToasts();
  const { openModal } = useCertsModals();
  const { logout } = useAuth();

  const handleOpenDeleteModal = () => {
    if (!cert?.id || cert.status !== CertStatus.in_progress) return;
    openModal({ type: "deleteCert", data: cert });
  };

  const handleRetryCertify = async () => {
    try {
      if (!cert?.id) return;
      showToast({ type: "loading" });

      await sleep(500);
      const res = await retryCertify(cert?.id);

      if (res.status === 204) {
        updateCertStatus(cert?.id, CertStatus.in_progress);
        hideToast();
      } else {
        showToast({
          type: "error",
          message: t("apiResponse:errors.base"),
          onClick: hideToast
        });
      }
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("apiResponse:errors.unauthorized"),
          onClick: hideToast
        });
      } else {
        // eslint-disable-next-line no-console
        console.error(e);

        showToast({
          type: "error",
          message: t("apiResponse:errors.cannotRetry"),
          onClick: hideToast
        });
      }
    }
  };

  const certSatusTransKey = formatCertStatus(cert?.status);

  const isCertError = cert?.status === CertStatus.error;
  const deleteDisabled = cert?.status === CertStatus.minted;
  const disabledDeleteBtn = deleteDisabled || !cert;

  return (
    <Stack rowGap={32} alignItems="center" justifyContent="center" className="cert-status-card" fullWidth>
      <p className="p0 regular relative">
        <span className="medium">{t("common:status")}: </span>
        {t(certSatusTransKey)}
        {cert?.status === CertStatus.in_progress && <Dots />}
      </p>

      {isCertError ? (
        <Button size="small" onClick={handleRetryCertify}>
          {t("common:tryAgain")}
        </Button>
      ) : (
        <>
          <Button size="small" onClick={handleOpenDeleteModal} disabled={disabledDeleteBtn}>
            {t("common:withdraw")}
          </Button>

          {deleteDisabled && <p className="p0 gray-0 text-center">{t("app:certDetails.withdrawDisabledInfo")}</p>}
        </>
      )}
    </Stack>
  );
};

export default CertStatusCard;
