import { useMemo } from "react";
import { DecodeHintType, BrowserMultiFormatReader } from "@zxing/library";

import { DELAY_BETWEEN_SCAN_ATTEMPTS } from "@config/config";

interface UseBrowserMultiFormatReaderOptions {
  hints?: Map<DecodeHintType, any>;
  delayBetweenScanAttempts?: number;
}

export const useBrowserMultiFormatReader = ({
  hints,
  delayBetweenScanAttempts = DELAY_BETWEEN_SCAN_ATTEMPTS
}: UseBrowserMultiFormatReaderOptions = {}) => {
  return useMemo<BrowserMultiFormatReader>(() => {
    const instance = new BrowserMultiFormatReader(hints);
    instance.timeBetweenDecodingAttempts = delayBetweenScanAttempts;

    return instance;
  }, [hints, delayBetweenScanAttempts]);
};
