import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@src/router/paths";
import { useCertsData } from "@src/contexts/CertsDataContext";

import DashboardLayout from "@src/components/layouts/DashboardLayout/DashboardLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import LoadingData from "./components/LoadingData/LoadingData";
import FetchingDataError from "./components/FetchingDataError/FetchingDataError";
import CertsList from "./components/CertsList/CertsList";
import CertsEmptyState from "./components/CertsEmptyState/CertsEmptyState";

import "./Dashboard.scss";

const Dashboard = () => {
  const navigate = useNavigate();
  const { certs, status } = useCertsData();

  const goToCertify = () => navigate(PATHS.certify.path);

  const { t } = useTranslation(["app", "common"]);

  if (status === "loading") {
    return <LoadingData />;
  }

  if (status === "failed") {
    return <FetchingDataError />;
  }

  return (
    <div className="dashboard">
      <Stack alignItems="center" className="dashboard-certify-section">
        <Button onClick={goToCertify} className="certify-btn">
          {t("common:getStarted")}
        </Button>
      </Stack>

      <Stack rowGap={16} fullSize className="dashboard-main">
        <p className="p0">{t("app:dashboard.lastCerts")}</p>

        {certs.length > 0 ? <CertsList /> : <CertsEmptyState />}
      </Stack>
    </div>
  );
};

export default (
  <DashboardLayout disableSrcoll>
    <Dashboard />
  </DashboardLayout>
);
