import dayjs, { Dayjs } from "dayjs";
import { FORMAT_DATE_TIME, FORMAT_DATE, FORMAT_TIME } from "@config/config";

import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export const formatDateTime = (date: string | number | undefined, timezone: string | undefined = undefined) => {
  return dayjs(date).tz(timezone).format(FORMAT_DATE_TIME);
};

export const formatUtcDateTime = (date?: string | Dayjs | number) => {
  return dayjs(date).utc().format();
};

export const formatDate = (date?: string | number, timezone: string | undefined = undefined) => {
  return dayjs(date).tz(timezone).format(FORMAT_DATE);
};

export const formatTime = (date?: string | number, timezone: string | undefined = undefined) => {
  return dayjs(date).tz(timezone).format(FORMAT_TIME);
};

export const getWeekdayName = (date: string | number, locales?: Intl.LocalesArgument) => {
  const parsedDate = new Date(date);
  return parsedDate.toLocaleDateString(locales, { weekday: "long" });
};

export const parseDateTimeWithTimezone = (date?: string | number, timezone: string | undefined = undefined) =>
  dayjs(date).tz(timezone);

export const formatDateWithWeekday = (
  date: string | number,
  locales: Intl.LocalesArgument = undefined,
  timezone: string | undefined = undefined
) => {
  const _date = formatDate(date, timezone);
  const _weekday = getWeekdayName(date, locales);
  const _time = formatTime(date, timezone);

  return `${_date} (${_weekday}) ${_time}`;
};
