/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */

import { CHECK_UPDATE_TIME } from "@src/config/config";
import { isLocalhost } from "@src/utils/browser/isLocalhost";

// This optional code is used to register a service worker.

type Config = {
  onSuccess?(registration: ServiceWorkerRegistration): void;
  onUpdate?(registration: ServiceWorkerRegistration): void;
};

const log = (content: string) => console.log("[service-worker-registration]: " + content);
const warn = (content: string) => console.warn("[service-worker-registration]: " + content);
const error = (content: string) => console.error("[service-worker-registration]: " + content);

const checkSw = (config?: Config) => {
  const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

  if (isLocalhost) {
    // This is running on localhost. Let's check if a service worker still exists or not.
    checkValidServiceWorker(swUrl, config);

    navigator.serviceWorker.ready.then(() => {
      log("This web app is being served cache-first by a service worker.");
    });
  } else {
    // Is not localhost. Just register service worker
    registerValidSW(swUrl, config);
  }
};

export function register(config?: Config) {
  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    // The URL constructor is available in all browsers that support SW.
    const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);

    if (publicUrl.origin !== window.location.origin) {
      // Our service worker won't work if PUBLIC_URL is on a different origin
      // from what our page is served on.
      return;
    }

    if (document.readyState === "complete") {
      checkSw(config);
    } else {
      window.addEventListener("load", () => {
        checkSw(config);
      });
    }
  }
}

function registerValidSW(swUrl: string, config?: Config) {
  navigator.serviceWorker
    .register(swUrl)
    .then((registration) => {
      // check for updates periodically every 10 minutes
      setInterval(() => {
        registration.update();
      }, CHECK_UPDATE_TIME);

      registration.onupdatefound = () => {
        const installingWorker = registration.installing;

        if (installingWorker === null) return;

        installingWorker.onstatechange = () => {
          if (installingWorker.state === "installed") {
            if (navigator.serviceWorker.controller) {
              // At this point, the updated precached content has been fetched,
              // but the previous service worker will still serve the older
              // content until all client tabs are closed.

              log("New content is available and will be used when all tabs for this page are closed.");

              // Execute callback
              if (config && config.onUpdate) {
                config.onUpdate(registration);
              }
            } else {
              // At this point, everything has been precached.
              log("Content is cached for offline use.");

              // Execute callback
              if (config && config.onSuccess) {
                config.onSuccess(registration);
              }
            }
          }
        };
      };
    })
    .catch((error) => {
      error("Error during service worker registration:", error);
    });
}

function checkValidServiceWorker(swUrl: string, config?: Config) {
  // Check if the service worker can be found. If it can't reload the page.
  fetch(swUrl, {
    headers: {
      "Service-Worker": "script"
    }
  })
    .then((response) => {
      // Ensure service worker exists, and that we really are getting a JS file.
      const contentType = response.headers.get("content-type");

      if (response.status === 404 || (contentType !== null && contentType.indexOf("javascript") === -1)) {
        // No service worker found. Probably a different app. Reload the page.
        navigator.serviceWorker.ready.then((registration) => {
          registration.unregister().then(() => {
            window.location.reload();
          });
        });
      } else {
        // Service worker found. Proceed as normal.
        registerValidSW(swUrl, config);
      }
    })
    .catch(() => {
      warn("No internet connection found. App is running in offline mode.");
    });
}

export function unregister() {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration.unregister();
      })
      .catch((error) => {
        error(error.message);
      });
  }
}
