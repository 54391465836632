export const ApiErrors = {
  paginationError: {
    message: "Wrong pagination parameters",
    errorCode: 1
  },
  noSeller: {
    message: "No seller",
    errorCode: 2
  },
  wrongSticker: {
    message: "Wrong sticker selected",
    tKey: "errors.wrongSticker",
    errorCode: 3
  },
  wrongProduct: {
    message: "Wrong product selected",
    tKey: "errors.wrongProduct",
    errorCode: 4
  },
  stickerProductCompanyMismatch: {
    message: "Sticker and product company mismatch",
    tKey: "errors.stickerProductCompanyMismatch",
    errorCode: 5
  },
  certificateAlreadyExists: {
    message: "Certificate already exists",
    tKey: "errors.certificateAlreadyExists",
    errorCode: 6
  },
  certificationFailed: {
    message: "Certification failed",
    tKey: "errors.certificationFailed",
    errorCode: 7
  },
  notFound: {
    message: "Not found",
    errorCode: 8,
    httpCode: 404
  },
  cannotDelete: {
    message: "Cannot delete",
    tKey: "errors.cannotDelete",
    errorCode: 9
  },
  cannotRetry: {
    message: "Cannot retry",
    tKey: "errors.cannotRetry",
    errorCode: 10
  },
  sellerDoesntBelongToCompany: {
    message: "Seller doesn't belong to company",
    errorCode: 11,
    httpCode: 404
  },
  provideIdOrSerialNumber: {
    message: "Provide id or serialNumber",
    tKey: "errors.provideIdOrSerialNumber",
    errorCode: 12
  },
  wrongProductTagTypeQrReq: {
    message: "Wrong product tag type, qr required",
    tKey: "errors.wrongProductTagTypeQrReq",
    errorCode: 13
  }
} as const;
