import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNotifications } from "@src/contexts/NotificationsContext";
import { useUpdateServiceWorker } from "../hooks/useUpdateServiceWorker";
import { useAddToHomeScreen } from "../hooks/useAddToHomeScreen";

import isMobile from "@src/utils/browser/isMobile";

const PwaServices = () => {
  const { notify, hideNotification } = useNotifications();
  const { t } = useTranslation("notifications");

  const { waitingWorker, showReload, reloadPage } = useUpdateServiceWorker();
  const { showInstall, appInstalled, handleInstall, dismissNotification } = useAddToHomeScreen();

  useEffect(() => {
    if (showReload && waitingWorker) {
      notify({ message: t("newVersion"), onClick: reloadPage });
    }
  }, [waitingWorker, showReload, reloadPage]);

  useEffect(() => {
    const appInstallNotificationId = "app-install-notification";

    if (showInstall && !appInstalled) {
      if (isMobile.iOS()) notify({ id: appInstallNotificationId, message: t("appInstall.ios") });
      else
        notify({
          id: appInstallNotificationId,
          message: t("appInstall.android"),
          onClick: handleInstall,
          onClose: dismissNotification
        });
    }

    if (!showInstall) {
      hideNotification(appInstallNotificationId);
    }
  }, [showInstall, appInstalled]);

  return <></>;
};

export default PwaServices;
