import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@src/router/paths";
import { ProductDto } from "@src/services/api/api.dtos";
import { useCertify } from "@src/contexts/CertifyContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import BaseProductCard from "@src/components/common/BaseProductCard/BaseProductCard";

import "./SearchProductsList.scss";

interface SearchProductsListProps {
  products: ProductDto[];
}

const SearchProductsList = ({ products }: SearchProductsListProps) => {
  const navigate = useNavigate();
  const { addProduct } = useCertify();

  const { t } = useTranslation(["app", "apiResponse"]);

  const navigateToCertify = () => navigate(PATHS.certify.path);

  const handleSelectProduct = (product: ProductDto) => () => {
    addProduct(product);
    navigateToCertify();
  };

  return (
    <Stack rowGap={12} fullSize className="certify-search-products-list">
      <p className="p0">{t("app:certifySearchProduct.matchingProducts")}</p>

      <div className="test">
        <Stack rowGap={12} fullWidth className="test2 mb-2">
          {products.map((product) => (
            <BaseProductCard key={product.id} product={product} onClick={handleSelectProduct(product)} />
          ))}
        </Stack>
      </div>
    </Stack>
  );
};

export default SearchProductsList;
