import React from "react";

import { IChildren } from "@src/types/IChildren.types";
import { useWakeLock } from "../hooks/useWakeLock";

const WakeLock = ({ children }: IChildren) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isSupported, request, release } = useWakeLock();
  request();

  return <>{children}</>;
};

export default WakeLock;
