export type AppStage = "dev" | "prod" | null;

// eslint-disable-next-line @typescript-eslint/no-var-requires
const appVersion = require("../../package.json").version;
const APP_STAGE_ENV: AppStage = (process.env.REACT_APP_STAGE as AppStage) || null;
const GO2NFT_API_URL_ENV = process.env.REACT_APP_GO2NFT_API_URL || null;

const getAppStage = () => {
  if (!APP_STAGE_ENV) {
    throw new Error(`There is not REACT_APP_STAGE provided in env`);
  }
  return APP_STAGE_ENV;
};

const getGo2NftApiUrl = () => {
  if (!GO2NFT_API_URL_ENV) {
    throw new Error(`There is not REACT_APP_GO2NFT_API_URL provided in env`);
  }
  return GO2NFT_API_URL_ENV;
};

export const APP_STAGE = getAppStage();
export const GO2NFT_API_URL = getGo2NftApiUrl();

export const APP_VERSION = appVersion;
export const GLOBAL_ABORT_TIMEOUT = 1000 * 8; //[ms]
export const GLOBAL_PROMISE_TIMEOUT = 1000 * 8; //[ms]
export const HANDLE_CLICK_TIMEOUT = 50; //[ms]

// pagination settings
export const DEFAULT_PAGE = 0;
export const DEFAULT_ITEM_PER_PAGE = 5;

export const FORMAT_DATE_TIME = "DD.MM.YYYY HH:mm";
export const FORMAT_DATE = "DD.MM.YYYY";
export const FORMAT_TIME = "HH:mm";

export const I18N_DEBUG_MODE = process.env.REACT_APP_I18N_DEBUG_MODE === "true";
export const DISABLE_DESKTOP_DEVICE = process.env.REACT_APP_DISABLE_DESKTOP_DEVICE !== "false"; // default true
export const NOTIFICATION_IGNORE_TIME = Number(process.env.REACT_APP_NOTIFICATION_IGNORE_TIME) || 1000 * 60 * 10; //[ms]
export const CHECK_UPDATE_TIME = Number(process.env.REACT_APP_CHECK_UPDATE_TIME) || 1000 * 60 * 10; //[ms]
export const DELAY_BETWEEN_SCAN_ATTEMPTS = Number(process.env.REACT_APP_DELAY_BETWEEN_SCAN_ATTEMPTS) || 500; //[ms]
export const TIME_TO_MINT = Number(process.env.REACT_APP_TIME_TO_MINT) || 1000 * 60 * 2; //[ms]
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL || undefined;
