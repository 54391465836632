import React from "react";
import classNames from "classnames";

import "./ActionButton.scss";

interface ActionButtonProps {
  children: React.ReactNode;
  id?: string;
  name?: string;
  title?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  className?: string;
  onClick?(): void;
}

const ActionButton = ({
  children,
  id,
  name,
  title,
  disabled,
  fullWidth,
  uppercase = true,
  className,
  onClick
}: ActionButtonProps) => {
  const classes = classNames("action-button", { "full-width": fullWidth, "text-uppercase": uppercase }, className);

  return (
    <button id={id} name={name} title={title} disabled={disabled} onClick={onClick} className={classes}>
      {children}
    </button>
  );
};

export default ActionButton;
