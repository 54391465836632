import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import { PATHS } from "@src/router/paths";
import { CertStatus, CertStatusType } from "@src/services/api/api.dtos";
import { getImage } from "@src/services/api/methods";
import { TIME_TO_MINT } from "@src/config/config";

import ClickableCard from "@src/components/common/ClickableCard/ClickableCard";
import Group from "@src/components/arrangement/Group/Group";
import Stack from "@src/components/arrangement/Stack/Stack";
import Dots from "@src/components/common/Dots/Dots";
import CountdownTimer from "@src/components/common/CountdownTimer/CountdownTimer";

import { getVariantCard } from "./getVariantCard";
import "./CertsProductCard.scss";

interface CertsProductCardProps {
  id: string;
  status: CertStatusType;
  title: string;
  imageId: string;
  productCode: string;
  createdAt: string;
  className?: string;
}

const CertsProductCard = ({ id, status, title, imageId, productCode, createdAt, className }: CertsProductCardProps) => {
  const navigate = useNavigate();

  const { t } = useTranslation("common");

  const navigateToCertDetials = (certId: string) => () => navigate(PATHS.certDetails.getPath(certId));

  const variant = getVariantCard(status);
  const imgUrl = getImage(imageId);

  const cardInfoText = useMemo(() => {
    switch (status) {
      case CertStatus.in_progress:
        return (
          <>
            {t("certCardLoadingInfo")}
            <Dots />
          </>
        );
      case CertStatus.error:
        return t("certCardErrorInfo");
      default:
        return productCode;
    }
  }, [status]);

  const showCountdownTimer = status === CertStatus.in_progress;
  const targetDate = new Date(Number(createdAt) + TIME_TO_MINT);

  const cardClasses = classNames("certs-product-card", className);
  const textInfoClasses = classNames("p0", {
    relative: status === CertStatus.in_progress,
    medium: status === CertStatus.in_progress || status === CertStatus.error,
    error: status === CertStatus.error
  });

  return (
    <ClickableCard
      key={id}
      variant={variant}
      padding="small"
      className={cardClasses}
      onClick={navigateToCertDetials(id)}
    >
      <Group colGap={12} fullWidth alignItems="flex-start">
        <img src={imgUrl} alt={title || "image of certified product"} width={96} height={96} />

        <Stack rowGap={10} className="mt-1">
          <p className="p0 medium certs-card-title">{title}</p>
          <p className={textInfoClasses}>{cardInfoText}</p>
        </Stack>
      </Group>

      {showCountdownTimer && <CountdownTimer targetDate={targetDate} className="certs-product-card-timer" />}
    </ClickableCard>
  );
};

export default CertsProductCard;
