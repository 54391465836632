import React, { useEffect, useState } from "react";

import { NOTIFICATION_IGNORE_TIME } from "@src/config/config";
import { BeforeInstallPromptEvent } from "@src/services/pwa/types/BeforeInstallPrompt";

import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";
import browserStorage from "@src/utils/browser/browserStorage";
import getWindowProperty from "@src/utils/browser/getWindowProperty";
import checkMediaProperty from "@src/utils/css/checkMediaProperty";
import isMobile from "@src/utils/browser/isMobile";

export const INSTALL_ACCEPTED = "accepted";
export const INSTALL_DISMISSED = "dismissed";

export enum A2HS_STORAGE_KEYS {
  A2HS_IDENTIFIER = "a2hs_notification",
  APP_INSTALLED = "app_installed"
}

const DISPLAY_STANDALONE = "display-mode: standalone";

const checkSupportsPwa = () => {
  return (
    // If PWA prompt exists.
    !!getWindowProperty().pwaInstallPrompt ||
    // Or is iOS and isn't installed.
    (isMobile.iOS() && !checkMediaProperty(DISPLAY_STANDALONE))
  );
};

export const useAddToHomeScreen = () => {
  const [showInstall, setShowInstall] = useState<boolean>(checkSupportsPwa());
  const [appInstalled, setAppInstalled] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = React.useState<BeforeInstallPromptEvent | null>(null);

  const handleBeforeInstall = (e: BeforeInstallPromptEvent) => {
    BrowserStorageManager.removeLocalStorageKey(A2HS_STORAGE_KEYS.APP_INSTALLED);
    e.preventDefault();
    setShowInstall(true);
    setPromptInstall(e);
  };

  const handleAppInstall = () => {
    BrowserStorageManager.writeLocalStorage(A2HS_STORAGE_KEYS.APP_INSTALLED, true);
  };

  useEffect(() => {
    const isInstalled = BrowserStorageManager.readLocalStorage<boolean>(A2HS_STORAGE_KEYS.APP_INSTALLED) || false;
    setAppInstalled(isInstalled);
  }, []);

  useEffect(() => {
    const isAppStandalone = checkMediaProperty(DISPLAY_STANDALONE);
    const isClosed = browserStorage.getItem(A2HS_STORAGE_KEYS.A2HS_IDENTIFIER);

    if (!isAppStandalone && !isClosed) {
      window.addEventListener("beforeinstallprompt", handleBeforeInstall);
    }

    window.addEventListener("appinstalled", handleAppInstall);

    return () => {
      window.removeEventListener("beforeinstallprompt", handleBeforeInstall);
      window.addEventListener("appinstalled", handleAppInstall);
    };
  }, []);

  const handleInstall = () => {
    if (!promptInstall) return;
    setShowInstall(false);

    promptInstall?.prompt();

    promptInstall.userChoice.then((choice) => {
      if (choice.outcome === INSTALL_ACCEPTED) {
        setShowInstall(false);
      }
      setPromptInstall(null);
    });
  };

  const dismissNotification = (): void => {
    setShowInstall(false);
    browserStorage.setItem(A2HS_STORAGE_KEYS.A2HS_IDENTIFIER, true, NOTIFICATION_IGNORE_TIME);
  };

  return { showInstall, appInstalled, handleInstall, dismissNotification };
};
