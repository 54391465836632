import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@src/router/paths";
import { useCertify } from "@src/contexts/CertifyContext";

import DashboardLayout from "@src/components/layouts/DashboardLayout/DashboardLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import CertifyProductCard from "./components/CertifyProductCard/CertifyProductCard";
import CertifyStickerCard from "./components/CertifyStickerCard/CertifyStickerCard";
import "./Certify.scss";

const Certify = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["app", "common"]);

  const { product, sticker, removeProduct, removeSticker, handleCertify } = useCertify();

  const handleCancel = () => {
    removeProduct();
    removeSticker();
    navigate(PATHS.dashboard.path);
  };

  const disabledCertfityBtn = !product || !sticker;

  return (
    <div className="certify">
      <p className="p0">{t("app:certify.title")}</p>

      <Stack rowGap={16} className="certify-steps">
        <CertifyProductCard />
        <CertifyStickerCard />
      </Stack>

      <Stack rowGap={16} alignItems="center" className="certify-buttons">
        <Button onClick={handleCertify} disabled={disabledCertfityBtn}>
          {t("app:certify.certify")}
        </Button>

        <Button variant="secondary" onClick={handleCancel}>
          {t("common:cancel")}
        </Button>
      </Stack>
    </div>
  );
};

export default (
  <DashboardLayout>
    <Certify />
  </DashboardLayout>
);
