import { Navigate, Outlet, RouteObject } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

import { PATHS } from "../paths";

import { ErrorBoundary } from "@src/components/common/ErrorBoundary/ErrorBoundary";
import ErrorBoundaryFallback from "@src/components/common/ErrorBoundaryFallback/ErrorBoundaryFallback";
import PwaServices from "@src/services/pwa/PwaServices/PwaServices";
import WakeLock from "@src/services/pwa/WakeLock/WakeLock";
import AppInfo from "@src/services/appInfo/AppVersion";

// Providers ------------------------------------------------------
import { AuthProvider } from "@src/contexts/AuthContext";
import { NotificationsProvider } from "@src/contexts/NotificationsContext";
import { ToastsProvider } from "@src/contexts/ToastsContext";
import { CertsDataProvider } from "@src/contexts/CertsDataContext";
import { CertifyProvider } from "@src/contexts/CertifyContext";
import { CertsModalsProvider } from "@src/contexts/CertsModalsContext";
import { SocketProvider } from "@src/services/socket/SocketContext";

// Guards ---------------------------------------------------------
import AuthGuard from "@src/guards/AuthGuard";
import DesktopDeviceGuard from "@src/guards/DesktopDeviceGuard/DesktopDeviceGuard";
import DevModeGuard from "@src/guards/DevModeGuard";
import OfflineNetworkGuard from "@src/guards/OfflineNetworkGuard/OfflineNetworkGuard";

// Layouts --------------------------------------------------------

// Views ---------------------------------------------------------
import AuthAccessCode from "@src/app/Auth/AuthAccessCode/AuthAccessCode";
import AuthQrCode from "@src/app/Auth/AuthQrCode/AuthQrCode";
import Login from "@src/app/Auth/Login/Login";

import CertsModals from "@src/components/modals/CertsModals";
import Dashboard from "@src/app/Dashboard/Dashboard";
import CertDetails from "@src/app/CertDetails/CertDetails";
import Certify from "@src/app/Certify/Certify";
import CertifyScanProduct from "@src/app/Certify/ScanProduct/ScanProduct";
import CertifySearchProduct from "@src/app/Certify/SearchProduct/SearchProduct";
import CertifyScanSticker from "@src/app/Certify/ScanSticker/ScanSticker";
import Settings from "@src/app/Settings/Settings";

import Debug from "@src/app/Debug/Debug";
import NotFound from "@src/app/NotFound/NotFound";

export const authRoutes: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: PATHS.auth.path,
      element: <Navigate to={PATHS.authLogin.path} />
    },
    {
      path: PATHS.authLogin.path,
      element: Login
    },
    {
      path: PATHS.authQrCode.path,
      element: AuthQrCode
    },
    {
      path: PATHS.authAccessCode.path,
      element: AuthAccessCode
    },
    {
      path: PATHS.notFound.path,
      element: NotFound
    }
  ]
};

export const mainRoutes: RouteObject = {
  element: (
    <AuthGuard>
      <SocketProvider>
        <CertsDataProvider>
          <CertifyProvider>
            <CertsModalsProvider>
              <WakeLock>
                <Outlet />
                <CertsModals />
              </WakeLock>
            </CertsModalsProvider>
          </CertifyProvider>
        </CertsDataProvider>
      </SocketProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: PATHS.main.path,
      element: <Navigate to={PATHS.dashboard.path} />
    },
    {
      path: PATHS.dashboard.path,
      element: Dashboard
    },
    {
      path: PATHS.certDetails.path,
      element: CertDetails
    },
    {
      path: PATHS.certify.path,
      element: Certify
    },
    {
      path: PATHS.certifyScanProduct.path,
      element: CertifyScanProduct
    },
    {
      path: PATHS.certifySearchProduct.path,
      element: CertifySearchProduct
    },
    {
      path: PATHS.certifyScanSticker.path,
      element: CertifyScanSticker
    },
    {
      path: PATHS.settings.path,
      element: Settings
    }
  ]
};

export const otherRoutes: RouteObject = {
  element: <Outlet />,
  children: [
    {
      path: PATHS.debug.path,
      element: <DevModeGuard>{Debug}</DevModeGuard>
    },
    {
      path: PATHS.notFound.path,
      element: NotFound
    }
  ]
};

export const appRoutes: RouteObject = {
  element: (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <HelmetProvider>
        <OfflineNetworkGuard>
          <DesktopDeviceGuard>
            <NotificationsProvider>
              <ToastsProvider>
                <AuthProvider>
                  <Outlet />
                  <PwaServices />
                  <AppInfo />
                </AuthProvider>
              </ToastsProvider>
            </NotificationsProvider>
          </DesktopDeviceGuard>
        </OfflineNetworkGuard>
      </HelmetProvider>
    </ErrorBoundary>
  ),
  children: [authRoutes, mainRoutes, otherRoutes]
};
