import React from "react";
import classNames from "classnames";

import Portal from "../Portal/Portal";
import "./Notification.scss";

type NotificationActivityState = "starting" | "idle" | "stopping";

interface NotificationProps {
  id?: string;
  activityState: NotificationActivityState;
  children: React.ReactNode;
  className?: string;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
  onClose?(): void;
}

const Notification = ({ id, activityState, children, className, onClick, onClose }: NotificationProps) => {
  const handleClose = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onClose && onClose();
  };

  const classes = classNames(
    "notification",
    `notification-${activityState}`,
    { "notification-clickable": onClick },
    className
  );

  return (
    <Portal type="notification">
      <div id={id} role="button" className={classes} onClick={onClick}>
        <div className="notification-content">
          <img
            src="/images/icons/icon-notification.svg"
            alt="notification"
            width={42}
            height={42}
            className="content-icon"
          />
          <div className="content-message">{children}</div>
        </div>

        <div className="notification-button">
          <button onClick={handleClose}>
            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="16" viewBox="0 0 11 12" fill="none">
              <path
                d="M10.9242 1.75736L6.75737 5.92424L11 10.1669L9.78782 11.3791L5.54518 7.13642L1.3783 11.3033L0.317643 10.2426L4.48452 6.07576L0.241882 1.83312L1.45407 0.620941L5.69671 4.86358L9.86358 0.696703L10.9242 1.75736Z"
                fill="#AFB1B3"
              />
            </svg>
          </button>
        </div>
      </div>
    </Portal>
  );
};

export default Notification;
