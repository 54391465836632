import React from "react";
import classNames from "classnames";

import "./Spinner.scss";

interface SpinnerProps {
  sizePx?: number;
  className?: string;
}

const Spinner = ({ sizePx = 28, className }: SpinnerProps) => {
  const classes = classNames("spinner", className);

  return (
    <div className={classes}>
      <img src="/images/spinner.png" alt="loading..." width={sizePx} height={sizePx} className="spinner-circle" />
    </div>
  );
};

export default Spinner;
