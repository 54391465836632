export enum DIRECTION {
  UP = -0b01,
  DOWN = 0b01
}

const isOverflowScrollable = (element: HTMLElement) => {
  const overflowType: string = getComputedStyle(element).overflowY;
  if (element === document.scrollingElement && overflowType === "visible") {
    return true;
  }

  if (overflowType !== "scroll" && overflowType !== "auto") {
    return false;
  }

  return true;
};

const isScrollable = (element: HTMLElement, direction: DIRECTION) => {
  if (!isOverflowScrollable(element)) return false;

  if (direction === DIRECTION.DOWN) {
    const bottomScroll = element.scrollTop + element.clientHeight;
    return bottomScroll < element.scrollHeight;
  }

  if (direction === DIRECTION.UP) return element.scrollTop > 0;

  throw new Error("unsupported direction");
};

export const isTreeScrollable = (element: HTMLElement, direction: DIRECTION): boolean => {
  if (isScrollable(element, direction)) return true;

  if (element.parentElement === null) return false;

  return isTreeScrollable(element.parentElement, direction);
};
