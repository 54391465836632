import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";
import Dots from "@src/components/common/Dots/Dots";
import Spinner from "@src/components/common/Spinner/Spinner";

import "./LoadingData.scss";

const LoadingData = () => {
  const { t } = useTranslation("common");

  return (
    <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize className="dashboard-loading-data">
      <p className="p0 relative">
        {t("downloadingData")}
        <Dots />
      </p>

      <Spinner sizePx={28} />
    </Stack>
  );
};

export default LoadingData;
