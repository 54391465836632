import React, { CSSProperties } from "react";
import classNames from "classnames";

import { IGroup } from "./Group.types";
import "./Group.scss";

const Group = ({
  colGap,
  rowGap,
  justifyContent = "flex-start",
  alignItems = "center",
  wrap = "unset",
  children,
  fullSize,
  fullWidth,
  equalWidth,
  className = "",
  onMouseEnter,
  onMouseLeave,
  onClick,
  onBlur
}: IGroup) => {
  const gap = `${rowGap ? rowGap : "0"}px ${colGap ? colGap : "0"}px`;

  const classes = classNames(
    "group",
    { "full-size": fullSize, "full-width": fullWidth, "equal-width": equalWidth },
    className
  );

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      onBlur={onBlur}
      className={classes}
      style={{
        gap,
        justifyContent,
        alignItems,
        flexWrap: wrap
      }}
    >
      {children}
    </div>
  );
};

export default Group;
