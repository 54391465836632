import React from "react";
import classNames from "classnames";

import Spinner from "@src/components/common/Spinner/Spinner";

import "./Toast.scss";

export type ToastType = "base" | "loading" | "error";

interface ToastProps {
  id?: string;
  children: React.ReactNode;
  type?: ToastType;
  className?: string;
  onClick?(): void;
}

const Toast = ({ id, children, type = "base", className, onClick }: ToastProps) => {
  const iconRender = () => {
    switch (type) {
      case "base":
        return null;
      case "loading":
        return (
          <div className="toast-icon">
            <Spinner sizePx={25} />
          </div>
        );
      case "error":
        return (
          <div className="toast-icon">
            <img src="/images/status/icon-error.svg" alt="error" width={28} height={26} />
          </div>
        );
      default:
        return null;
    }
  };

  const handleClick = () => {
    onClick && onClick();
  };

  const classes = classNames("toast", `toast-${type}`, { "toast-clickable": !!onClick }, className);

  return (
    <div id={id} className={classes} onClick={handleClick}>
      {iconRender()}
      <div>{children}</div>
    </div>
  );
};

export default Toast;
