import React, { useState } from "react";

import { IContext } from "@src/types/IContext.types";
import { ToastType } from "@src/components/overlay/Toast/Toast";

import ToastsPanel from "@src/components/overlay/ToastsPanel/ToastsPanel";

interface Toast {
  id?: string;
  type: ToastType;
  message?: string;
  onClick?(): void;
}

interface ContextValue {
  toasts: Toast[];
  showToast: (toast: Toast) => void;
  hideToast: () => void;
}

const ToastsContext = React.createContext(null as any);

export const ToastsProvider = ({ children }: IContext) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const showToast = (toast: Toast) => setToasts([toast]);
  const hideToast = () => setToasts([]);

  const contextValue: ContextValue = {
    toasts,
    showToast,
    hideToast
  };

  return (
    <ToastsContext.Provider value={contextValue}>
      {children}
      <ToastsPanel />
    </ToastsContext.Provider>
  );
};

export const useToasts = (): ContextValue => React.useContext(ToastsContext);
