import React, { useCallback, useContext, useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { useTranslation } from "react-i18next";

import { FetchStatus } from "@src/types/api/FetchStatus.types";
import { CertDto, CertStatusType } from "@src/services/api/api.dtos";
import { IContext } from "@src/types/IContext.types";
import { CertEventData, SocketEvents } from "@src/services/socket/Socket.types";
import { DEFAULT_PAGE, DEFAULT_ITEM_PER_PAGE } from "@src/config/config";

import { getCertsList } from "@src/services/api/methods";
import { sleep } from "@src/utils/sleep";

import useSocketSubscribe from "@src/services/socket/useSocketSubscribe";
import { useToasts } from "./ToastsContext";
import { useAuth } from "./AuthContext";

export interface CertsData {
  status: FetchStatus;
  certs: CertDto[];
  error: Error | AxiosError | null;
}

interface ContextValue extends CertsData {
  fetchCertsList: (page?: number, perPage?: number) => Promise<void>;
  fetchMoreCertsList: (page?: number, perPage?: number) => Promise<AxiosResponse<CertDto[]> | undefined>;
  getCertById: (certId: string) => CertDto | null;
  addNewCert: (newCert: CertDto) => void;
  updateCert: (certId: string, updatedCert: CertDto) => void;
  updateCertStatus: (certId: string, status: CertStatusType) => void;
  removeCert: (certId: string) => void;
}

const initialData = (): CertsData => ({
  status: "loading",
  certs: [],
  error: null
});

const CertsDataContext = React.createContext(null as any);

export const CertsDataProvider = ({ children }: IContext) => {
  const [certsData, setCertsData] = useState<CertsData>(initialData());

  const { logout } = useAuth();
  const { showToast, hideToast } = useToasts();
  const { t } = useTranslation("apiResponse");

  useEffect(() => {
    fetchCertsList();
  }, []);

  useSocketSubscribe(SocketEvents.CERT_ADDED, (data: CertEventData) => addNewCert(data.cert));
  useSocketSubscribe(SocketEvents.CERT_STATUS_CHANGED, (data: CertEventData) =>
    updateCertStatus(data.cert.id, data.cert.status)
  );
  useSocketSubscribe(SocketEvents.CERT_DELETED, (data: CertEventData) => removeCert(data.cert.id));
  useSocketSubscribe(SocketEvents.CONNECT, () => fetchCertsList());

  const _resetError = () => {
    setCertsData((prevState) => ({ ...prevState, error: null }));
  };

  const fetchCertsList = async (page: number = DEFAULT_PAGE, perPage: number = DEFAULT_ITEM_PER_PAGE) => {
    _resetError();

    try {
      await sleep(500);
      const res = await getCertsList({ page, perPage });

      if (res.status === 200) {
        setCertsData((prevState) => ({ ...prevState, certs: res.data, status: "success" }));
      }
    } catch (e: any) {
      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("errors.unauthorized"),
          onClick: hideToast
        });
      }

      setCertsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const fetchMoreCertsList = async (page: number = DEFAULT_PAGE, perPage: number = DEFAULT_ITEM_PER_PAGE) => {
    _resetError();

    try {
      await sleep(500);
      const res = await getCertsList({ page, perPage });

      if (res.status === 200) {
        setCertsData((prevState) => ({ ...prevState, certs: [...prevState.certs, ...res.data], status: "success" }));
      }

      return res;
    } catch (e: any) {
      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("errors.unauthorized"),
          onClick: hideToast
        });
      }

      setCertsData((prevState) => ({ ...prevState, status: "failed", error: e }));
    }
  };

  const getCertById = useCallback(
    (certId: string) => {
      const foundCert = certsData.certs.find((cert) => cert.id === certId);
      return foundCert ?? null;
    },
    [certsData]
  );

  const addNewCert = useCallback((newCert: CertDto) => {
    const foundCert = certsData.certs.find((cert) => cert.id === newCert.id);
    if (foundCert) return;

    setCertsData((prevState) => ({ ...prevState, certs: [newCert, ...prevState.certs] }));
    [certsData];
  }, []);

  const updateCert = useCallback(
    (certId: string, updatedCert: CertDto) => {
      const foundCert = certsData.certs.find((cert) => cert.id === certId);
      if (!foundCert) return;

      setCertsData((prevState) => ({
        ...prevState,
        certs: prevState.certs.map((cert) => {
          if (cert.id === certId) {
            return { ...cert, ...updatedCert };
          }

          return cert;
        })
      }));
    },
    [certsData]
  );

  const updateCertStatus = useCallback(
    (certId: string, status: CertStatusType) => {
      const currentCertStatus = certsData.certs.find((cert) => cert.id === certId);

      if (!currentCertStatus || currentCertStatus.status === status) return;

      setCertsData((prevState) => ({
        ...prevState,
        certs: prevState.certs.map((cert) => {
          if (cert.id === certId) {
            return { ...cert, status };
          }
          return cert;
        })
      }));
    },
    [certsData]
  );

  const removeCert = useCallback(
    (certId: string) => {
      const foundCert = certsData.certs.find((cert) => cert.id === certId);
      if (!foundCert) return;

      setCertsData((prevState) => ({
        ...prevState,
        certs: prevState.certs.filter((cert) => cert.id !== certId)
      }));
    },
    [certsData]
  );

  const contextValue: ContextValue = {
    ...certsData,
    fetchCertsList,
    fetchMoreCertsList,
    getCertById,
    addNewCert,
    updateCert,
    updateCertStatus,
    removeCert
  };

  return <CertsDataContext.Provider value={contextValue}>{children}</CertsDataContext.Provider>;
};

export const useCertsData = (): ContextValue => useContext(CertsDataContext);
