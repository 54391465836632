import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { APP_VERSION } from "@src/config/config";
import { APP_LANG, STORAGE_APP_UPDATED } from "@src/config/helpers";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";

import { useNotifications } from "@src/contexts/NotificationsContext";

export interface IAppInfo {
  version: string;
  lang: string;
}

const APP_INFO_STORAGE_KEY = "app_info";

const AppInfo = () => {
  const { notify, hideActiveNotification } = useNotifications();
  const { t } = useTranslation("notifications");

  const saveAppInfo = () => {
    const appInfo: IAppInfo = {
      version: APP_VERSION,
      lang: APP_LANG
    };
    BrowserStorageManager.writeLocalStorage(APP_INFO_STORAGE_KEY, appInfo);
  };

  const checkAppHasBeenUpdated = () => {
    const isUpdated = BrowserStorageManager.readLocalStorage<boolean>(STORAGE_APP_UPDATED);

    if (isUpdated) {
      notify({
        message: t("appHasBeenUpdated"),
        onClose: hideActiveNotification
      });

      BrowserStorageManager.removeLocalStorageKey(STORAGE_APP_UPDATED);
    }
  };

  useEffect(saveAppInfo, []);

  useEffect(() => {
    saveAppInfo();
    checkAppHasBeenUpdated();
  }, []);

  return <></>;
};

export default AppInfo;
