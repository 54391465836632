import { deviceInfo } from "@src/utils/browser/deviceInfo";
import { isBackCameraLabel } from "./isBackCameraLabel";

export enum PreferredCameraTypes {
  BackFacingCamera = "back", // Prefer back facing camera
  FrontFacingCamera = "front" // Prefer front facing camera
}

export type PreferredCameraType = "back" | "front";

export class SelectedCamera {
  readonly deviceId: string;
  readonly groupId: string;
  readonly facing: PreferredCameraType;
  readonly label: string;

  constructor(mdi: MediaDeviceInfo, facing: PreferredCameraType, label?: string) {
    this.deviceId = mdi.deviceId;
    this.facing = facing;
    this.groupId = mdi.groupId;

    // apply custom label
    if (label) {
      this.label = label;
    } else {
      this.label = mdi.label;
    }
  }
}

export interface CameraDevices {
  frontCameras: SelectedCamera[];
  backCameras: SelectedCamera[];
}

export const getCameraDevices = async (): Promise<CameraDevices> => {
  const frontCameras: SelectedCamera[] = [];
  const backCameras: SelectedCamera[] = [];

  let devices = await navigator.mediaDevices.enumerateDevices();

  // if permission is not given, label of video devices will be empty string
  if (devices.filter((device) => device.kind === "videoinput").every((device) => device.label === "")) {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        facingMode: { ideal: "environment" }
      },
      audio: false
    });

    // enumerate devices again - now the label field should be non-empty, as we have a stream active
    // (even if we didn't get persistent permission for camera)
    devices = await navigator.mediaDevices.enumerateDevices();

    // close the stream, as we don't need it anymore
    stream.getTracks().forEach((track) => track.stop());
  }

  const cameras = devices.filter((device) => device.kind === "videoinput");

  let backCameraIterator = 0;
  let frontCameraIterator = 0;

  for (const camera of cameras) {
    // phone back camera
    if (isBackCameraLabel(camera.label)) {
      backCameraIterator++;
      let backLabel: string | undefined = undefined;

      // we apply a custom label on Android devices
      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (deviceInfo.isAndroidDevice) {
        backLabel = `Back camera ${backCameraIterator}`;
      }

      backCameras.push(new SelectedCamera(camera, PreferredCameraTypes.BackFacingCamera, backLabel));
    }

    // front camera or non-phone camera
    else {
      frontCameraIterator++;
      let frontLabel: string | undefined = undefined;

      // eslint-disable-next-line react-hooks/rules-of-hooks
      if (deviceInfo.isAndroidDevice) {
        // we apply a custom label on Android devices
        frontLabel = `Front camera ${frontCameraIterator}`;
      }

      frontCameras.push(new SelectedCamera(camera, PreferredCameraTypes.FrontFacingCamera, frontLabel));
    }
  }

  return {
    frontCameras,
    backCameras
  };
};

export const selectCamera = async (
  cameraId: string | null,
  preferredCameraType: PreferredCameraType
): Promise<SelectedCamera | null> => {
  const { frontCameras, backCameras } = await getCameraDevices();

  if (!frontCameras.length && !backCameras.length) return null;

  // decide from which array the camera will be selected
  let cameraPool: SelectedCamera[] = backCameras.length > 0 ? backCameras : frontCameras;

  // if there is at least one back facing camera and user prefers back facing camera, use that as a selection pool
  if (preferredCameraType === PreferredCameraTypes.BackFacingCamera && backCameras.length > 0) {
    cameraPool = backCameras;
  }
  // if there is at least one front facing camera and is preferred by user, use that as a selection pool
  if (preferredCameraType === PreferredCameraTypes.FrontFacingCamera && frontCameras.length > 0) {
    cameraPool = frontCameras;
  }
  // otherwise use whichever pool is non-empty

  // sort camera pool by label
  cameraPool = cameraPool.sort((camera1, camera2) => camera1.label.localeCompare(camera2.label));

  // Check if cameras are labeled with resolution information, take the higher-resolution one in that case
  // Otherwise pick the last camera (Samsung wide on most Android devices)
  let selectedCameraIndex = cameraPool.length - 1;

  // on iOS 16.3+, select the virtual dual or triple cameras
  const iosTripleCameraIndex = cameraPool.findIndex((camera) => camera.label === "Back Triple Camera");
  const iosDualCameraIndex = cameraPool.findIndex((camera) => camera.label === "Back Dual Wide Camera");

  if (iosDualCameraIndex >= 0) selectedCameraIndex = iosDualCameraIndex;
  if (iosTripleCameraIndex >= 0) selectedCameraIndex = iosTripleCameraIndex;

  // gets camera resolutions from the device name, if exists
  const cameraResolutions: number[] = cameraPool.map((camera) => {
    const CAMERA_RESOLUTIONS_REGEX = RegExp(/\b([0-9]+)MP?\b/, "i");
    const match = CAMERA_RESOLUTIONS_REGEX.exec(camera.label);

    if (match !== null) {
      return parseInt(match[1], 10);
    } else return NaN;
  });

  // picks camera  based on highest resolution in the name
  if (!cameraResolutions.some((cameraResolution) => Number.isNaN(cameraResolution))) {
    selectedCameraIndex = cameraResolutions.lastIndexOf(Math.max(...cameraResolutions));
  }

  // picks camera based on the provided device id
  if (cameraId) {
    let cameraDevice: SelectedCamera;

    cameraDevice = frontCameras.filter((device) => device.deviceId === cameraId)[0];
    if (!cameraDevice) {
      cameraDevice = backCameras.filter((device) => device.deviceId === cameraId)[0];
    }

    return cameraDevice || null;
  }

  return cameraPool[selectedCameraIndex];
};
