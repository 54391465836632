import { useCallback, useEffect, useRef } from "react";
import { DecodeHintType, Result, DecodeContinuouslyCallback } from "@zxing/library";

import { GLOBAL_PROMISE_TIMEOUT } from "@src/config/config";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";
import { timeoutPromise } from "@src/utils/timeoutPromise";

import { getCameraDevices, selectCamera } from "./CameraUtils";
import { useBrowserMultiFormatReader } from "./useBrowserMultiFormatReader";
import { useTorch } from "./useTorch";

const SAVED_DEVICE_ID_KEY = "CAMERA_DEVICE_ID";

export interface UseCameraOptions {
  paused?: boolean;
  hints?: Map<DecodeHintType, any>;
  deviceId?: string;
  delayBetweenScanAttempts?: number;
  onResult?: (result: Result) => void;
  onDecodeError?: (error: Error) => void;
  onError?: (error: Error) => void;
}

export const useCamera = (options: UseCameraOptions) => {
  const {
    paused = false,
    hints,
    delayBetweenScanAttempts,
    onResult = () => {},
    onDecodeError = () => {},
    onError = () => {}
  } = options;

  const ref = useRef<HTMLVideoElement | null>(null);
  const resultHandlerRef = useRef(onResult);
  const errorDecodeHandlerRef = useRef(onDecodeError);
  const errorHandlerRef = useRef(onError);

  const reader = useBrowserMultiFormatReader({
    hints,
    delayBetweenScanAttempts
  });

  const { init: torchInit, ...torch } = useTorch({
    resetStream: async () => {
      stopDecoding();
      await startDecoding();
    }
  });

  const getDeviceId = async () => {
    // eslint-disable-next-line no-useless-catch
    try {
      // if deviceId was passed as a parameter, we return it
      if (options.deviceId) return options.deviceId;

      // Checking if we have any deviceId saved in local storage
      const savedDeviceId = BrowserStorageManager.readLocalStorage<string>(SAVED_DEVICE_ID_KEY);

      // check if the saved deviceId is on the list of available cameras of the given device\
      const { frontCameras, backCameras } = await getCameraDevices();

      const camerasDevices = frontCameras.concat(backCameras);

      const isCorrectDeviceId = camerasDevices.some((device) => device.deviceId === savedDeviceId);

      // if there is a stored deviceId and the device has a camera with this id, return this id
      // if the stored deviceId is incorrect, delete it from local storage
      if (savedDeviceId && isCorrectDeviceId) return savedDeviceId;
      if (!isCorrectDeviceId) BrowserStorageManager.removeLocalStorageKey(SAVED_DEVICE_ID_KEY);

      const newDeviceId = await selectCamera(null, "back");

      if (newDeviceId) {
        BrowserStorageManager.writeLocalStorage(SAVED_DEVICE_ID_KEY, newDeviceId.deviceId);
        return newDeviceId.deviceId;
      }
      return null;
    } catch (e) {
      throw e;
    }
  };

  const decodeCallback = useCallback<DecodeContinuouslyCallback>((result, error) => {
    if (result) resultHandlerRef.current(result);
    if (error) errorDecodeHandlerRef.current(error);
  }, []);

  const startDecoding = useCallback(async () => {
    try {
      if (!ref.current || paused) return;

      const deviceId = await timeoutPromise(getDeviceId(), GLOBAL_PROMISE_TIMEOUT).catch((e) => {
        throw e;
      });

      if (!deviceId) return;

      await reader.decodeFromVideoDevice(deviceId, ref.current, decodeCallback);

      const mediaStream = ref.current?.srcObject;
      const videoTrack = mediaStream ? (mediaStream as MediaStream).getVideoTracks()[0] : null;

      if (videoTrack) torchInit(videoTrack);
    } catch (e) {
      errorHandlerRef.current(e as Error);
    }
  }, [reader, paused, decodeCallback, torchInit, ref]);

  const stopDecoding = useCallback(() => {
    reader.reset();
  }, [reader]);

  useEffect(() => {
    resultHandlerRef.current = onResult;
  }, [onResult]);

  useEffect(() => {
    errorDecodeHandlerRef.current = onDecodeError;
  }, [onDecodeError]);

  useEffect(() => {
    errorHandlerRef.current = onError;
  }, [onError]);

  useEffect(() => {
    startDecoding();

    return () => {
      stopDecoding();
    };
  }, [startDecoding, stopDecoding]);

  return {
    ref,
    torch
  };
};
