import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";

import "./SearchEmptyState.scss";

const SearchEmptyState = () => {
  const { t } = useTranslation("app");

  return (
    <Stack
      rowGap={32}
      fullWidth
      alignItems="center"
      justifyContent="center"
      className="certify-search-products-empty-state"
    >
      <svg width="40" height="44" viewBox="0 0 40 44" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22 20H10M14 28H10M26 12H10M34 19V11.6C34 8.23969 34 6.55953 33.346 5.27606C32.7708 4.14708 31.8529 3.2292 30.7239 2.65396C29.4405 2 27.7603 2 24.4 2H11.6C8.23969 2 6.55953 2 5.27606 2.65396C4.14708 3.2292 3.2292 4.14708 2.65396 5.27606C2 6.55953 2 8.23969 2 11.6V32.4C2 35.7603 2 37.4405 2.65396 38.7239C3.2292 39.8529 4.14708 40.7708 5.27606 41.346C6.55953 42 8.23969 42 11.6 42H17M38 42L35 39M37 34C37 37.866 33.866 41 30 41C26.134 41 23 37.866 23 34C23 30.134 26.134 27 30 27C33.866 27 37 30.134 37 34Z"
          stroke="#AFB1B3"
          strokeWidth="3"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>

      <Stack fullWidth alignItems="center">
        <p className="p0 gray-0">{t("certifySearchProduct.noRecords")}</p>
        <p className="p0 gray-0">{t("certifySearchProduct.useSearchBar")}</p>
      </Stack>
    </Stack>
  );
};

export default SearchEmptyState;
