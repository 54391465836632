import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AxiosError } from "axios";

import { PATHS } from "@src/router/paths";
import { getProducts } from "@src/services/api/methods";
import { sleep } from "@src/utils/sleep";

import { useToasts } from "@src/contexts/ToastsContext";
import { useCertify } from "@src/contexts/CertifyContext";
import { useAuth } from "@src/contexts/AuthContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Stack from "@src/components/arrangement/Stack/Stack";
import CodesScanner from "@src/components/common/CodesScanner/CodesScanner";
import Button from "@src/components/dataEntry/Button/Button";

import "./ScanProduct.scss";

const ScanProduct = () => {
  const [productCode, setProductCode] = useState("");

  const navigate = useNavigate();
  const { showToast, hideToast } = useToasts();
  const { sticker, addProduct } = useCertify();
  const { logout } = useAuth();

  const { t } = useTranslation(["app", "apiResponse"]);

  const navigateTo = (path: string) => () => navigate(path);

  const handleReset = () => {
    setProductCode("");
    hideToast();
  };

  const handleResult = async (result: string) => {
    try {
      if (productCode) return;

      setProductCode(result);
      showToast({ type: "loading" });

      await sleep(500);
      const res = await getProducts(result);

      if (res?.status === 200) {
        if (res.data.length <= 0) {
          showToast({
            type: "error",
            message: t("apiResponse:errors.productNotFound"),
            onClick: handleReset
          });
        } else if (res.data.length === 1) {
          if (sticker && sticker.company !== res.data[0].company) {
            showToast({
              type: "error",
              message: t("apiResponse:errors.productInvalidComapny"),
              onClick: handleReset
            });
          } else {
            addProduct(res.data[0]);
            hideToast();
            navigate(PATHS.certify.path);
          }
        } else {
          showToast({
            type: "error",
            message: t("apiResponse:errors.base"),
            onClick: handleReset
          });
        }
      } else {
        showToast({
          type: "error",
          message: t("apiResponse:errors.base"),
          onClick: handleReset
        });
      }
    } catch (e: any) {
      // Handling unexpected errors
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("apiResponse:errors.unauthorized"),
          onClick: handleReset
        });
      } else {
        showToast({
          type: "error",
          message: t("apiResponse:errors.base"),
          onClick: handleReset
        });
      }
    }
  };

  return (
    <Stack className="certify-scan-product">
      <CodesScanner onResult={handleResult} />

      <Stack fullWidth alignItems="center" justifyContent="center" className="certify-scan-product-button">
        <Button onClick={navigateTo(PATHS.certifySearchProduct.path)}>
          {t("app:certifyScanProduct.searchByName")}
        </Button>
      </Stack>
    </Stack>
  );
};

export default (
  <LayoutWithNavbar title="app:certifyScanProduct.navbar" withoutPadding>
    <ScanProduct />
  </LayoutWithNavbar>
);
