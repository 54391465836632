import { useCallback, useState } from "react";
import { AxiosResponse, AxiosError } from "axios";

import { FetchStatus } from "@src/types/api/FetchStatus.types";

interface UseRequestOptions<T, Y extends any[]> {
  request: (...args: Y) => Promise<AxiosResponse<T>>;
  onSuccess?: (response: AxiosResponse<T>) => void;
  onError?: (error: AxiosError | Error) => void;
}

interface UseRequestReturn<T, Y extends any[]> {
  response: AxiosResponse<T> | null;
  error: AxiosError | null;
  loading: boolean;
  status: FetchStatus | null;
  call: (...args: Y) => Promise<AxiosResponse<T, any> | undefined>;
  reset: () => void;
}

function useRequest<T, Y extends any[]>({
  request,
  onSuccess,
  onError
}: UseRequestOptions<T, Y>): UseRequestReturn<T, Y> {
  const [response, setResponse] = useState<AxiosResponse<T> | null>(null);
  const [error, setError] = useState<AxiosError | null>(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState<FetchStatus | null>(null);

  const call = useCallback(
    async (...args: Y) => {
      reset();
      setLoading(true);
      setStatus("loading");

      try {
        const res = await request(...args);

        setResponse(res);
        setStatus("success");

        if (onSuccess) onSuccess(res);

        return res;
      } catch (e: unknown) {
        setStatus("failed");

        if (!(e instanceof AxiosError)) throw e;

        setError(e);

        if (onError) onError(e);
      } finally {
        setLoading(false);
      }
    },
    [request]
  );

  const reset = useCallback(() => {
    setResponse(null);
    setError(null);
    setLoading(false);
  }, []);

  return { response, error, loading, status, call, reset };
}

export default useRequest;
