import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@src/router/paths";
import { useAuth } from "@src/contexts/AuthContext";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import ClickableCard from "@src/components/common/ClickableCard/ClickableCard";

import "./Login.scss";

const Login = () => {
  const navigate = useNavigate();
  const { restoreDefaultAuthState } = useAuth();

  const { t } = useTranslation("auth");

  const handleNavigate = (path: string) => () => navigate(path);

  React.useEffect(() => {
    restoreDefaultAuthState();
  }, []);

  return (
    <div className="auth-login">
      <Stack rowGap={8} className="mb-4">
        <h1>{t("login.title")}</h1>
        <p className="p0">{t("login.description")}</p>
      </Stack>

      <Stack rowGap={16}>
        <ClickableCard onClick={handleNavigate(PATHS.authQrCode.path)}>
          <Group colGap={16}>
            <img src="/images/icons/icon-qr-code.svg" alt="auth qr code" width={32} height={32} />
            <p className="p0">{t("login.authQrCode")}</p>
          </Group>
        </ClickableCard>

        <ClickableCard onClick={handleNavigate(PATHS.authAccessCode.path)}>
          <Group colGap={16}>
            <img src="/images/icons/icon-auth-code.svg" alt="auth access code" width={32} height={15} />
            <p className="p0">{t("login.authAccessCode")}</p>
          </Group>
        </ClickableCard>
      </Stack>
    </div>
  );
};

export default (
  <BaseLayout>
    <Login />
  </BaseLayout>
);
