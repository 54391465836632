import React from "react";
import { useTranslation } from "react-i18next";

import { APP_VERSION } from "@src/config/config";
import { formatDateTime } from "@src/utils/formatDateTime";
import { useAuth } from "@src/contexts/AuthContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import Button from "@src/components/dataEntry/Button/Button";

import "./Settings.scss";

const Settings = () => {
  const { user, logout } = useAuth();
  const { t } = useTranslation(["app", "common"]);

  const accountExpirationDateFormatted = Number(user?.expiresAt)
    ? formatDateTime(user?.expiresAt)
    : t("common:noExpirationDate");

  const userHasCompanies = user?.companies && user?.companies.length > 0;
  const companyName = user?.companies[0]?.name || "-";
  const companyImageUrl = user?.companies[0]?.avatar;

  return (
    <div className="settings">
      <Stack rowGap={16} fullWidth className="settings-main">
        <Stack rowGap={16} fullWidth className="settings-main-user card-white">
          <Stack rowGap={8}>
            <p className="p0">{t("app:settings.user")}</p>
            <p className="p0 gray-3">{user?.name}</p>
          </Stack>

          <Stack rowGap={8}>
            <p className="p0">{t("app:settings.expirationAcount")}</p>
            <p className="p0 gray-3">{accountExpirationDateFormatted}</p>
          </Stack>
        </Stack>

        {userHasCompanies && (
          <Group colGap={16} fullWidth className="settings-main-company card-white">
            <img src={companyImageUrl} alt="company logo" width={32} height={32} className="company-logo" />

            <Stack rowGap={8}>
              <p className="p0">{t("app:settings.company")}</p>
              <p className="p0 gray-3">{companyName}</p>
            </Stack>
          </Group>
        )}
      </Stack>

      <Stack fullWidth alignItems="center" justifyContent="center" className="settings-buttons">
        <Button onClick={logout}>{t("common:logout")}</Button>
      </Stack>

      <p className="p0 gray-0 app-version">
        {t("common:version")}: {APP_VERSION}
      </p>
    </div>
  );
};

export default (
  <LayoutWithNavbar title="app:settings.navbar">
    <Settings />
  </LayoutWithNavbar>
);
