import React from "react";
import { useTranslation } from "react-i18next";

import { useCertsData } from "@src/contexts/CertsDataContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import "./FetchingDataError.scss";

const FetchingDataError = () => {
  const { fetchCertsList } = useCertsData();

  const { t } = useTranslation(["apiResponse", "common"]);

  return (
    <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize className="dashboard-fetching-data-error">
      <p className="p0 error text-center">{t("apiResponse:errors.fetchingData")}</p>
      <img src="/images/status/icon-error-2.svg" alt="fetching data failed" />

      <Button size="small" className="mt-3" onClick={fetchCertsList}>
        {t("common:tryAgain")}
      </Button>
    </Stack>
  );
};

export default FetchingDataError;
