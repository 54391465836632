import { apiClient } from "./client.instance";
import { GO2NFT_API_URL } from "@src/config/config";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";

import * as ApiDto from "./api.dtos";
import { PaginationMeta } from "@src/types/api/Pagination.types";

export const getUser = async (authCode: string) => {
  const url = `/seller/me`;
  const method = "GET";
  const headers = {
    "Auth-Code": authCode
  };

  return await apiClient.sendRequest<ApiDto.UserDto>({
    method,
    url,
    headers
  });
};

export const getCompanyInfo = async (companyUUID: string) => {
  const url = `/seller/me/${companyUUID}`;
  const method = "GET";

  return await apiClient.sendRequest<ApiDto.CompanyDto[]>({
    method,
    url
  });
};

export const getCertsList = async (queryParams?: Partial<PaginationMeta>) => {
  const url = `/seller/certs`;
  const method = "GET";
  const params = queryParams || { page: 0, perPage: 25 };

  return await apiClient.sendRequest<ApiDto.CertDto[]>({
    method,
    url,
    params
  });
};

export const getImage = (uuid: string) => {
  const authCode = BrowserStorageManager.readLocalStorage("auth_code");
  if (authCode && uuid) return `${GO2NFT_API_URL}seller/images/${uuid}/thumb?auth_code=${authCode}`;
};

export const getProducts = async (searchValue?: string, queryParams?: Partial<PaginationMeta>) => {
  const url = `/seller/products/${searchValue}`;
  const method = "GET";
  const params = queryParams || { page: 0, perPage: 1000 };

  return await apiClient.sendRequest<ApiDto.ProductDto[]>({
    method,
    url,
    params
  });
};

export const validateSticker = async ({ id, serialNumber }: { id?: string; serialNumber?: string }) => {
  const url = `/seller/stickers`;
  const method = "POST";
  const data = {
    id,
    serialNumber
  };

  return await apiClient.sendRequest<ApiDto.StickerDto>({
    method,
    url,
    data
  });
};

export const certify = async (productId: string, stickerId: string) => {
  const url = `/seller/certs`;
  const method = "POST";
  const data = {
    product: productId,
    sticker: stickerId
  };

  return await apiClient.sendRequest<ApiDto.CertDto>({
    method,
    url,
    data
  });
};

export const retryCertify = async (certId: string) => {
  const url = `/seller/certs/${certId}/retry`;
  const method = "POST";

  return await apiClient.sendRequest({
    method,
    url
  });
};

export const deleteCert = async (certId: string) => {
  const url = `/seller/certs/${certId}`;
  const method = "DELETE";

  return await apiClient.sendRequest({
    method,
    url
  });
};
