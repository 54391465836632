import React from "react";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { useNavigate } from "react-router-dom";

import { PATHS } from "@src/router/paths";
import { ApiError, CertDto, CertStatus } from "@src/services/api/api.dtos";
import { ApiErrors } from "@src/services/api/api.errors";
import { deleteCert } from "@src/services/api/methods";
import { sleep } from "@src/utils/sleep";

import { useCertsModals } from "@src/contexts/CertsModalsContext";
import { useToasts } from "@src/contexts/ToastsContext";
import { useAuth } from "@src/contexts/AuthContext";
import { useCertsData } from "@src/contexts/CertsDataContext";

import Portal from "@src/components/overlay/Portal/Portal";
import Backdrop from "@src/components/overlay/Backdrop/Backdrop";
import Modal from "@src/components/overlay/Modal/Modal";
import Stack from "@src/components/arrangement/Stack/Stack";
import Group from "@src/components/arrangement/Group/Group";
import ActionButton from "@src/components/dataEntry/ActionButton/ActionButton";

import "./DeleteCert.scss";

interface DeleteCertProps {
  cert: CertDto;
}

const DeleteCert = ({ cert }: DeleteCertProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation(["app", "common", "apiResponse"]);

  const { closeModal } = useCertsModals();
  const { showToast, hideToast } = useToasts();
  const { logout } = useAuth();
  const { updateCertStatus, removeCert } = useCertsData();

  const handleDeleteCert = async () => {
    try {
      if (!cert?.id || cert.status !== "in_progress") return;

      closeModal();
      showToast({ type: "loading" });

      await sleep(500);
      const res = await deleteCert(cert.id);

      if (res.status === 204) {
        removeCert(cert.id);
        hideToast();
        navigate(PATHS.dashboard.path);
      } else {
        showToast({
          type: "error",
          message: t("apiResponse:errors.base"),
          onClick: hideToast
        });
      }
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("apiResponse:errors.unauthorized"),
          onClick: hideToast
        });
      } else if (
        e.response?.data.errors.some((error: ApiError) => error.errorCode === ApiErrors.cannotDelete.errorCode)
      ) {
        updateCertStatus(cert.id, CertStatus.minted);

        showToast({
          type: "error",
          message: t("apiResponse:errors.cannotDelete"),
          onClick: hideToast
        });
      } else {
        // eslint-disable-next-line no-console
        console.error(e);

        showToast({
          type: "error",
          message: t("apiResponse:errors.base"),
          onClick: hideToast
        });
      }
    }
  };

  return (
    <Portal>
      <Backdrop open>
        <Modal>
          <Stack className="delete-cert-modal">
            <p className="p0">{t("app:modals.deleteCert.title")}</p>

            <Group colGap={10} justifyContent="flex-end" fullWidth className="mt-4">
              <ActionButton onClick={closeModal}>{t("common:cancel")}</ActionButton>
              <ActionButton onClick={handleDeleteCert}>{t("common:yes")}</ActionButton>
            </Group>
          </Stack>
        </Modal>
      </Backdrop>
    </Portal>
  );
};

export default DeleteCert;
