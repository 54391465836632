import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { getProducts } from "@src/services/api/methods";
import { debounce } from "@src/utils/debounce";
import useRequest from "@src/hooks/useRequest";
import { useAuth } from "@src/contexts/AuthContext";
import { useToasts } from "@src/contexts/ToastsContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Stack from "@src/components/arrangement/Stack/Stack";
import Input from "@src/components/dataEntry/Input/Input";

import SearchProductsList from "./components/SearchProductsList/SearchProductsList";
import SearchEmptyState from "./components/SearchEmptyState/SearchEmptyState";
import SearchProductsLoading from "./components/SearchProductsLoading/SearchProductsLoading";
import SearchProductsError from "./components/SearchProductsError/SearchProductsError";

import "./SearchProduct.scss";

const DEBOUNCED_CHANGE_TIME = 500;

const SearchProduct = () => {
  const { logout } = useAuth();
  const { showToast, hideToast } = useToasts();
  const { t } = useTranslation(["app", "apiResponse"]);

  const {
    call: getProductsRequest,
    response,
    loading,
    error,
    reset
  } = useRequest({ request: (searchValue: string) => getProducts(searchValue) });

  const products = response?.data || [];

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchValue = e.target.value;

    if (searchValue.length > 0 && searchValue.length < 3) return;
    if (searchValue.length === 0) return reset();

    await getProductsRequest(searchValue.trim());
  };

  useEffect(() => {
    if (error?.response?.status === 401) {
      logout();
      showToast({
        type: "error",
        message: t("apiResponse:errors.unauthorized"),
        onClick: hideToast
      });
    }
  }, [error]);

  const debouncedChange = debounce(onChange as any, DEBOUNCED_CHANGE_TIME);

  const displayEmptyState = !loading && products.length === 0 && !error;

  return (
    <div className="certify-search-product">
      <Stack fullWidth className="card-white">
        <Input
          name="searchProduct"
          placeholder={t("app:certifySearchProduct.input.placeholder")}
          onChange={debouncedChange}
        />
      </Stack>

      <Stack fullSize className="certify-search-product-main">
        {displayEmptyState && <SearchEmptyState />}
        {loading && <SearchProductsLoading />}
        {error && <SearchProductsError />}
        {products.length > 0 && <SearchProductsList products={products} />}
      </Stack>
    </div>
  );
};

export default (
  <LayoutWithNavbar title="app:certifySearchProduct.navbar" disableSrcoll>
    <SearchProduct />
  </LayoutWithNavbar>
);
