import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { PATHS } from "@src/router/paths";
import { sleep } from "@src/utils/sleep";
import { ApiError } from "@src/services/api/api.dtos";
import { ApiErrors } from "@src/services/api/api.errors";
import { validateSticker } from "@src/services/api/methods";

import { useToasts } from "@src/contexts/ToastsContext";
import { useCertify } from "@src/contexts/CertifyContext";
import { useAuth } from "@src/contexts/AuthContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import CodesScanner from "@src/components/common/CodesScanner/CodesScanner";

import { getStickerCode } from "./utils/getStickerCode";
import "./ScanSticker.scss";

const ScanSticker = () => {
  const [stickerCode, setStickerCode] = useState<string>("");

  const navigate = useNavigate();
  const { showToast, hideToast } = useToasts();
  const { product, addSticker } = useCertify();
  const { logout } = useAuth();

  const { t } = useTranslation("apiResponse");

  const handleReset = () => {
    setStickerCode("");
    hideToast();
  };

  const handleScanCode = async (result: string) => {
    try {
      if (stickerCode) return;

      setStickerCode(result);
      showToast({ type: "loading" });

      const { isUrl, code } = getStickerCode(result);

      const payload = {
        id: isUrl ? code : undefined,
        serialNumber: !isUrl ? code : undefined
      };

      await sleep(500);
      const res = await validateSticker(payload);

      if (res?.status === 200) {
        if (product && product.company !== res.data.company) {
          showToast({
            type: "error",
            message: t("errors.stickerInvalidComapny"),
            onClick: handleReset
          });
        } else if (res.data.cert) {
          showToast({
            type: "error",
            message: t("errors.stickerUsed"),
            onClick: handleReset
          });
        } else {
          addSticker(res.data);
          hideToast();
          navigate(PATHS.certify.path);
        }
      } else {
        showToast({
          type: "error",
          message: t("errors.base"),
          onClick: handleReset
        });
      }
    } catch (e) {
      if (!(e instanceof AxiosError)) throw e;

      if (e.response?.status === 404) {
        showToast({
          type: "error",
          message: t("errors.stickerNotFound"),
          onClick: handleReset
        });
      } else if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("errors.unauthorized"),
          onClick: handleReset
        });
      } else if (
        e.response?.data.errors.some(
          (error: ApiError) => error.errorCode === ApiErrors.provideIdOrSerialNumber.errorCode
        )
      ) {
        showToast({
          type: "error",
          message: t("errors.provideIdOrSerialNumber"),
          onClick: handleReset
        });
      } else {
        showToast({
          type: "error",
          message: t("errors.base"),
          onClick: handleReset
        });
      }
    }
  };

  return (
    <div className="certify-scan-sticker">
      <CodesScanner onResult={handleScanCode} />
    </div>
  );
};

export default (
  <LayoutWithNavbar title="app:certifyScanSticker.navbar" withoutPadding>
    <ScanSticker />
  </LayoutWithNavbar>
);
