import { FC } from "react";
import { useTranslation } from "react-i18next";

import Dots from "@src/components/common/Dots/Dots";
import Toast from "../Toast/Toast";

export const BaseToast: FC<{ id?: string; message?: string; onClick?(): void }> = ({ id, message, onClick }) => {
  return (
    <Toast id={id} type="base" onClick={onClick}>
      <p className="p0">{message}</p>
    </Toast>
  );
};

export const LoadingToast: FC<{ id?: string; message?: string; onClick?(): void }> = ({ id, message, onClick }) => {
  const { t } = useTranslation("common");

  return (
    <Toast id={id} type="loading" onClick={onClick}>
      <p className="p0 relative">
        {message || t("pleaseWait")}
        <Dots />
      </p>
    </Toast>
  );
};

export const ErrorToast: FC<{ id?: string; message?: string; onClick?(): void }> = ({ id, message, onClick }) => {
  return (
    <Toast id={id} type="error" onClick={onClick}>
      <p className="p0 relative">{message}</p>
    </Toast>
  );
};
