import React from "react";
import { useTranslation } from "react-i18next";

import Group from "@src/components/arrangement/Group/Group";

const DefaultEndListContent = () => {
  const { t } = useTranslation("common");

  return (
    <Group justifyContent="center" fullWidth>
      <p className="p0 gray-0">{t("endList")}</p>
    </Group>
  );
};

export default DefaultEndListContent;
