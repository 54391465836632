import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { PATHS } from "@src/router/paths";
import { useCertify } from "@src/contexts/CertifyContext";

import ClickableCard from "@src/components/common/ClickableCard/ClickableCard";
import Group from "@src/components/arrangement/Group/Group";
import Stack from "@src/components/arrangement/Stack/Stack";

import "./CertifyStickerCard.scss";

const CertifyStickerCard = () => {
  const navigate = useNavigate();
  const { sticker } = useCertify();

  const { t } = useTranslation(["app", "apiResponse"]);

  const navigateTo = (path: string) => () => navigate(path);

  const cardVariant = sticker ? "success" : "base";
  const imgHref = sticker ? "/images/certify-search-sticker-active.png" : "/images/certify-search-sticker.png";
  const imgAlt = sticker ? "scanned sticker" : "search sticker";

  return (
    <ClickableCard
      variant={cardVariant}
      padding="small"
      onClick={navigateTo(PATHS.certifyScanSticker.path)}
      className="certify-sticker-card"
    >
      <Group colGap={16} fullWidth alignItems={sticker ? "flex-start" : "center"}>
        <img src={imgHref} alt={imgAlt} width={96} height={96} />

        {sticker ? (
          <Stack rowGap={10} className="mt-1">
            <p className="p0 medium">{t("app:certify.stickerScanned")}</p>
            <p className="p0 regular">
              {t("app:certify.serialNumber")} {sticker.displaySerialNumber}
            </p>
          </Stack>
        ) : (
          <p className="p0 full-width">{t("app:certify.scanQR")}</p>
        )}
      </Group>
    </ClickableCard>
  );
};

export default CertifyStickerCard;
