import React from "react";
import classNames from "classnames";

import { HANDLE_CLICK_TIMEOUT } from "@src/config/config";

import "./Button.scss";

type ButtonVariant = "primary" | "secondary";
type ButtonSize = "normal" | "small";

interface ButtonProps {
  children: React.ReactNode;
  variant?: ButtonVariant;
  size?: ButtonSize;
  id?: string;
  name?: string;
  title?: string;
  disabled?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  className?: string;
  onClick?(): void;
}

const Button = ({
  children,
  variant = "primary",
  size = "normal",
  id,
  name,
  title,
  disabled,
  fullWidth,
  uppercase = true,
  className,
  onClick
}: ButtonProps) => {
  const classes = classNames(
    "button",
    `button-${variant}`,
    `button-${size}`,
    className,
    { "full-width": fullWidth },
    { "text-uppercase": uppercase }
  );

  const handleClick = () => {
    if (!onClick) return;

    setTimeout(() => {
      onClick();
    }, HANDLE_CLICK_TIMEOUT);
  };

  return (
    <button id={id} name={name} title={title} disabled={disabled} onClick={handleClick} className={classes}>
      {children}
    </button>
  );
};

export default Button;
