import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

import { PATHS } from "@src/router/paths";
import { IContext } from "@src/types/IContext.types";
import { ApiError, ProductDto, StickerDto } from "@src/services/api/api.dtos";
import { findErrorByCode } from "@src/services/api/utils/findErrorByCode";

import { certify } from "@src/services/api/methods";
// import { useCertsData } from "./CertsDataContext";
import { useToasts } from "./ToastsContext";
import { useAuth } from "./AuthContext";

interface ContextValue {
  product: ProductDto | null;
  sticker: StickerDto | null;
  addProduct: (product: ProductDto) => void;
  removeProduct: () => void;
  addSticker: (sticker: StickerDto) => void;
  removeSticker: () => void;
  handleCertify: () => Promise<void>;
}

const CertifyContext = React.createContext(null as any);

export const CertifyProvider = ({ children }: IContext) => {
  const [product, setProduct] = useState<ProductDto | null>(null);
  const [sticker, setSticker] = useState<StickerDto | null>(null);

  const navigate = useNavigate();
  // const { addNewCert } = useCertsData();
  const { showToast, hideToast } = useToasts();
  const { logout } = useAuth();
  const { t } = useTranslation("apiResponse");

  const addProduct = (product: ProductDto) => setProduct(product);
  const removeProduct = () => setProduct(null);

  const addSticker = (sticker: StickerDto) => setSticker(sticker);
  const removeSticker = () => setSticker(null);

  const handleCertify = async () => {
    try {
      if (!product || !sticker) return;
      showToast({ type: "loading" });

      const res = await certify(product.id, sticker.id);

      if (res.status === 201) {
        // addNewCert(res.data);
        hideToast();
        removeProduct();
        removeSticker();
        navigate(PATHS.dashboard.path);
      }
    } catch (e: any) {
      if (!(e instanceof AxiosError)) throw e;

      // eslint-disable-next-line no-console
      console.error(e);

      if (e.response?.status === 401) {
        logout();
        showToast({
          type: "error",
          message: t("errors.unauthorized"),
          onClick: hideToast
        });
      } else if (e.response?.data.errors) {
        const errorCode = (e.response.data.errors as ApiError[])[0].errorCode;
        const error = findErrorByCode(errorCode);

        if (error && "tKey" in error) {
          showToast({
            type: "error",
            message: t(error.tKey as any),
            onClick: hideToast
          });
        } else {
          showToast({
            type: "error",
            message: t("errors.base"),
            onClick: hideToast
          });
        }
      } else {
        showToast({
          type: "error",
          message: t("errors.base"),
          onClick: hideToast
        });
      }
    }
  };

  const contextValue: ContextValue = {
    product,
    sticker,
    addProduct,
    removeProduct,
    addSticker,
    removeSticker,
    handleCertify
  };

  return <CertifyContext.Provider value={contextValue}>{children}</CertifyContext.Provider>;
};

export const useCertify = (): ContextValue => React.useContext(CertifyContext);
