import React from "react";

import { IChildren } from "@src/types/IChildren.types";

import useNetworkStatus from "@src/hooks/useNetworkStatus";
import OfflineNetwork from "./OfflineNetwork/OfflineNetwork";

const OfflineNetworkGuard = ({ children }: IChildren) => {
  const isOnline = useNetworkStatus();

  if (!isOnline) return <OfflineNetwork />;

  return <>{children}</>;
};

export default OfflineNetworkGuard;
