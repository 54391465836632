import React from "react";

import { useNotifications } from "@src/contexts/NotificationsContext";
import Notification from "../Notification/Notification";

const NotificationsPanel = () => {
  const { notifications, hideNotification } = useNotifications();
  const closestNotification = notifications[0];

  if (!closestNotification) return <></>;

  const { id, activityState, message, onClick, onClose } = closestNotification;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    onClick && onClick(event);
  };

  const handleClose = () => {
    onClose && onClose();
    hideNotification(id);
  };

  return (
    <Notification id={id} activityState={activityState} onClick={onClick && handleClick} onClose={handleClose}>
      {message}
    </Notification>
  );
};

export default NotificationsPanel;
