import { Path } from "./common/Path";

export const PATHS = {
  // Auth paths ---------------------------------------------

  auth: new Path({
    path: "/auth",
    pathName: "Auth"
  }),
  authLogin: new Path({
    path: "/auth/login",
    pathName: "Login"
  }),
  authQrCode: new Path({
    path: "/auth/login/qr-code",
    pathName: "Authorization by qr code"
  }),
  authAccessCode: new Path({
    path: "/auth/login/access-code",
    pathName: "Authorization by access code"
  }),

  // Auth paths ---------------------------------------------

  // App paths ----------------------------------------------

  main: new Path({
    path: "/"
  }),
  dashboard: new Path({
    path: "/dashboard",
    pathName: "Dashboard"
  }),
  certDetails: new Path({
    path: "/cert/:certId",
    pathName: "Cert Details",
    ref: (certId: string): string => `/cert/${certId}`
  }),
  certify: new Path({
    path: "/certify",
    pathName: "Certify"
  }),
  certifyScanProduct: new Path({
    path: "/certify/product/scan",
    pathName: "Certify scan product"
  }),
  certifySearchProduct: new Path({
    path: "/certify/product/search",
    pathName: "Certify search product"
  }),
  certifyScanSticker: new Path({
    path: "/certify/sticker/scan",
    pathName: "Certify scan sticker"
  }),
  settings: new Path({
    path: "/settings",
    pathName: "Settings"
  }),

  // App paths ----------------------------------------------

  // Common paths -------------------------------------------

  debug: new Path({
    path: "/debug"
  }),
  notFound: new Path({
    path: "/*"
  })

  // Common paths -------------------------------------------
} as const;

export const mainNavPaths = [PATHS.main];
