import React from "react";
import { useTranslation } from "react-i18next";

import Stack from "@src/components/arrangement/Stack/Stack";

import "./SearchProductsError.scss";

const SearchProductsError = () => {
  const { t } = useTranslation("apiResponse");

  return (
    <Stack rowGap={16} alignItems="center" justifyContent="center" fullSize className="certify-search-products-error">
      <p className="p0 error text-center">{t("errors.fetchingData")}</p>
      <img src="/images/status/icon-error-2.svg" alt="fetching data failed" />
    </Stack>
  );
};

export default SearchProductsError;
