import React from "react";
import classNames from "classnames";

import { HANDLE_CLICK_TIMEOUT } from "@src/config/config";
import Spinner from "../Spinner/Spinner";

import "./ClickableCard.scss";

type CardVariant = "base" | "loading" | "success" | "error" | "certified";
type PaddingSize = "normal" | "small";

interface ClickableCardProps {
  children: React.ReactNode;
  id?: string;
  ref?: React.RefObject<HTMLDivElement>;
  disabled?: boolean;
  variant?: CardVariant;
  padding?: PaddingSize;
  className?: string;
  onClick?(): void;
}

const cardIconRender = (variant: CardVariant) => {
  switch (variant) {
    case "loading":
      return <Spinner sizePx={18} className="clickable-card-status-icon" />;
    case "success":
      return (
        <img
          src="/images/status/icon-success.svg"
          alt="success icon"
          width={32}
          height={32}
          className="clickable-card-status-icon-success"
        />
      );
    case "error":
      return (
        <img
          src="/images/status/icon-error-2.svg"
          alt="error icon"
          width={17}
          height={17}
          className="clickable-card-status-icon"
        />
      );
    case "certified":
      return (
        <img
          src="/images/status/icon-certified.png"
          alt="certified icon"
          width={22}
          height={24}
          className="clickable-card-status-icon"
        />
      );
    default:
      return <></>;
  }
};

const ClickableCard = ({
  children,
  id,
  ref,
  disabled,
  variant = "base",
  padding = "normal",
  className,
  onClick
}: ClickableCardProps) => {
  const handleClick = () => {
    if (disabled || !onClick) return;

    setTimeout(() => {
      onClick();
    }, HANDLE_CLICK_TIMEOUT);
  };

  const classes = classNames(
    "clickable-card",
    `clickable-card-${variant}`,
    `clickable-card-padding-${padding}`,
    { "clickable-disabled": disabled },
    className
  );

  return (
    <div role="button" id={id} ref={ref} className={classes} onClick={handleClick}>
      {children}
      {cardIconRender(variant)}
    </div>
  );
};

export default ClickableCard;
