import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Button/Button";

import "./NotFound.scss";

const NotFound = () => {
  const navigate = useNavigate();
  const { t } = useTranslation("layout");

  const goBack = () => navigate(-1);

  return (
    <div className="not-found">
      <Stack rowGap={16} alignItems="center" className="not-found-content">
        <h1>{t("pageNotFound.title")}</h1>
        <p className="p0 text-center">{t("pageNotFound.description")}</p>

        <Button className="mt-3" onClick={goBack}>
          {t("pageNotFound.button")}
        </Button>
      </Stack>
    </div>
  );
};

export default (
  <BaseLayout>
    <NotFound />
  </BaseLayout>
);
