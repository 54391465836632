import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { PATHS } from "@src/router/paths";
import Group from "@src/components/arrangement/Group/Group";
import ActionIcon from "@src/components/dataEntry/ActionIcon/ActionIcon";
import ArrowLeft from "@src/components/icons/ArrowLeft";

import "./Navbar.scss";

interface NavbarProps {
  title?: string;
  hideNavigate?: boolean;
  onNavigate?(): void;
  className?: string;
}

const Navbar = ({ title, hideNavigate, onNavigate, className }: NavbarProps) => {
  const navigate = useNavigate();

  const classes = classNames("navbar", className);

  const goBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(PATHS.dashboard.path);
    }
  };

  const handleNavigate = () => {
    if (onNavigate) return onNavigate();
    return goBack();
  };

  return (
    <div className={classes}>
      <Group colGap={8} className="navbar-content">
        {!hideNavigate && (
          <ActionIcon onClick={handleNavigate}>
            <ArrowLeft />
          </ActionIcon>
        )}
        <p className="p2 text-uppercase">{title}</p>
      </Group>
    </div>
  );
};

export default Navbar;
