import { useState, useEffect } from "react";

interface CountdownTimerResult {
  days?: number;
  hours?: number;
  minutes?: number;
  seconds: number;
}

export enum CountdownTimerFormat {
  DAYS_HOURS_MINUTES_SECONDS = "DAYS_HOURS_MINUTES_SECONDS",
  HOURS_MINUTES_SECONDS = "HOURS_MINUTES_SECONDS",
  MINUTES_SECONDS = "MINUTES_SECONDS",
  SECONDS = "SECONDS"
}

// Convert to millisecond
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const calculateTimeLeft = (targetDate: Date): number => {
  const currentDate = new Date().getTime();
  return Math.max(0, targetDate.getTime() - currentDate);
};

const formatCountdown = (timeLeft: number, format: CountdownTimerFormat = CountdownTimerFormat.MINUTES_SECONDS) => {
  const days = Math.floor(timeLeft / DAY);
  const hours = Math.floor((timeLeft % DAY) / HOUR);
  const minutes = Math.floor((timeLeft % HOUR) / MINUTE);
  const seconds = Math.floor((timeLeft % MINUTE) / SECOND);

  if (format === CountdownTimerFormat.DAYS_HOURS_MINUTES_SECONDS) {
    return { days, hours, minutes, seconds };
  } else if (format === CountdownTimerFormat.HOURS_MINUTES_SECONDS) {
    return { hours: hours + days * 24, minutes, seconds };
  } else if (format === CountdownTimerFormat.MINUTES_SECONDS) {
    return { minutes: minutes + days * 24 * 60 + hours * 60, seconds };
  } else {
    return { seconds: seconds + days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60 };
  }
};

export const useCountdownTimer = (
  targetDate: Date,
  format: CountdownTimerFormat = CountdownTimerFormat.MINUTES_SECONDS
): CountdownTimerResult => {
  const [timeLeft, setTimeLeft] = useState<number>(calculateTimeLeft(targetDate));

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft(targetDate);

      setTimeLeft(newTimeLeft);

      if (newTimeLeft <= 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [targetDate]);

  return formatCountdown(timeLeft, format);
};
