import { useState, useCallback, useEffect } from "react";

import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { reloadApp } from "@src/utils/reloadApp";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";
import { STORAGE_APP_UPDATED } from "@src/config/helpers";

export const useUpdateServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = useState<boolean>(false);

  const onSWUpdate = useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }, []);

  const reloadPage = useCallback(() => {
    waitingWorker?.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    BrowserStorageManager.writeLocalStorage(STORAGE_APP_UPDATED, true);
    reloadApp();
  }, [waitingWorker]);

  useEffect(() => {
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate
    });
  }, [onSWUpdate]);

  return { showReload, waitingWorker, reloadPage };
};
