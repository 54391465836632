type OrientationType = "landscape-primary" | "landscape-secondary" | "portrait-primary" | "portrait-secondary";

export enum OsName {
  Linux = "linux",
  MacOS = "mac_os",
  Unix = "unix",
  Windows = "windows",
  WindowsPhone = "Windows Phone",
  Android = "android",
  iOS = "ios",
  Unknown = "unknown"
}

export enum BrowserName {
  Edge = "Microsoft Edge",
  Chrome = "Chrome",
  Firefox = "Firefox",
  Safari = "Safari",
  Opera = "Opera",
  InternetExplorer = "Internet Explorer",
  Unknown = "Unknown"
}

type OsInfo = {
  name: OsName;
  version?: number[];
};

type BrowserInfo = {
  name: BrowserName;
  version?: number[];
};

type PlatformType = "desktop" | "tablet" | "mobile";

export class DeviceInfo {
  private userAgent: string = navigator.userAgent || navigator.vendor || (window as any).opera || undefined;

  private supportedScreenOrientation =
    (screen?.orientation || {}).type ?? (screen as any).mozOrientation ?? (screen as any).msOrientation;
  private safariScreenOrientation: OrientationType =
    !screen?.orientation && matchMedia("(orientation: portrait)").matches ? "portrait-primary" : "landscape-primary";

  screenOrientation: OrientationType =
    this.supportedScreenOrientation ?? this.safariScreenOrientation ?? "portrait-primary";

  isAndroidDevice = this.detectOsInfo().name === OsName.Android;
  isIosDevice = this.detectOsInfo().name === OsName.iOS;

  constructor() {
    this.handleOrientationChange();
  }

  get platform(): PlatformType {
    if (this.isMobileDevice()) return "mobile";
    else if (this.isTabletDevice()) return "tablet";
    else return "desktop";
  }

  get osInfo(): OsInfo {
    return this.detectOsInfo();
  }

  get browserInfo(): BrowserInfo {
    return this.detectBrowserInfo();
  }

  private detectOsInfo(): OsInfo {
    const osInfoRegex = /(Windows NT|Windows|Mac OS X|Android|iOS|Linux)(?: ([\d.]+))?/i;
    const match = this.userAgent.match(osInfoRegex);

    if (match) {
      const osName = match[1] as OsName;
      const osVersion = match[2] ? this.parseVersion(match[2]) : undefined;

      return { name: osName, version: osVersion };
    } else {
      return { name: OsName.Unknown, version: undefined };
    }
  }

  private detectBrowserInfo(): BrowserInfo {
    const browserInfoRegex = /(Edge|Chrome|Firefox|Safari|Opera|MSIE|Trident)\/([\d.]+)/i;
    const match = this.userAgent.match(browserInfoRegex);

    if (match) {
      const browserName = match[1] as BrowserName;
      const browserVersion = match[2] ? this.parseVersion(match[2]) : undefined;

      return { name: browserName, version: browserVersion };
    } else {
      return { name: BrowserName.Unknown, version: undefined };
    }
  }

  private handleOrientationChange() {
    if (screen.orientation) {
      screen.orientation.addEventListener("change", (ev: Event) => {
        this.screenOrientation = (ev.target as ScreenOrientation)?.type ?? "portrait-primary";
      });
    }
  }

  isMobileDevice(): boolean {
    const regex = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i];
    return regex.some((b) => this.userAgent.match(b));
  }

  isTabletDevice(): boolean {
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/;
    return regex.test(this.userAgent.toLowerCase());
  }

  isDesktopDevice = (): boolean => !this.isMobileDevice() && !this.isTabletDevice();

  parseVersion(versionString: string): number[] {
    const versionParts = versionString.split(".").map(Number);

    while (versionParts.length < 3) {
      versionParts.push(0);
    }

    return versionParts;
  }
}

export const deviceInfo = new DeviceInfo();
