import React, { useEffect } from "react";

import useLockedBody from "@hooks/useLockedBody";
import useClickKey from "@hooks/useClickKey";
import ClickAwayListener from "@components/common/ClickAwayListener/ClickAwayListener";

import "./Modal.scss";

interface ModalProps {
  children: React.ReactNode;
  onClose?: () => void;
}

const Modal = ({ children, onClose }: ModalProps) => {
  const { handleLocked, handleUnlocked } = useLockedBody();

  const handleClose = () => {
    if (onClose) return onClose();
  };

  useClickKey("Escape", handleClose);

  useEffect(() => {
    handleLocked();

    return () => {
      handleUnlocked();
    };
  }, []);

  return (
    <ClickAwayListener mouseEvent="mouseup" onClickAway={handleClose}>
      <div className="modal">{children}</div>
    </ClickAwayListener>
  );
};

export default Modal;
