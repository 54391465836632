import getWindowProperty from "./getWindowProperty";

type StorageEntryName = string;
type StorageEntryValue = any;

export class BrowserStorageManager {
  private static get _sessionStorage(): Storage {
    return getWindowProperty().localStorage || {};
  }

  private static get _localStorage(): Storage {
    return getWindowProperty().localStorage || {};
  }

  // Session storage utils -----------------------------------------------------

  static readSessionStorage<T>(key: string): T | null;
  static readSessionStorage<T>(key: string, otherwise: T): T;
  static readSessionStorage<T>(key: string, otherwise?: T): T | null {
    const data: string | null = this._sessionStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data);
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  static writeSessionStorage(key: StorageEntryName, value: StorageEntryValue): void | Error {
    if (!this._sessionStorage) {
      throw new Error("setItem called outside of window scope!");
    }

    if (!value) {
      return this.removeSessionStorageKey(key);
    }

    this._sessionStorage.setItem(key, JSON.stringify(value));
  }

  static removeSessionStorageKey(key: string) {
    this._sessionStorage.removeItem(key);
  }

  static removeSessionStorageItems(keys: string[]) {
    keys.forEach((key) => this._sessionStorage.removeItem(key));
  }

  static clearSessionStorage() {
    this._sessionStorage.clear();
  }

  // Session storage utils -----------------------------------------------------

  // Local storage utils -------------------------------------------------------

  static readLocalStorage<T>(key: string): T | null;
  static readLocalStorage<T>(key: string, otherwise: T): T;
  static readLocalStorage<T>(key: string, otherwise?: T): T | null {
    const data: string | null = this._localStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data);
    }

    if (otherwise) {
      return otherwise;
    }

    return null;
  }

  static writeLocalStorage(key: StorageEntryName, value: StorageEntryValue): void | Error {
    if (!this._localStorage) {
      throw new Error("setItem called outside of window scope!");
    }

    if (!value) {
      return this.removeLocalStorageKey(key);
    }

    this._localStorage.setItem(key, JSON.stringify(value));
  }

  static removeLocalStorageKey(key: string) {
    this._localStorage.removeItem(key);
  }

  static removeLocalStorageItems(keys: string[]) {
    keys.forEach((key) => this._localStorage.removeItem(key));
  }

  static clearLocalStorage() {
    this._localStorage.clear();
  }

  // Local storage utils -------------------------------------------------------
}
