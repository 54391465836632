import React from "react";

import { Router } from "../router/router";
import { welcome } from "@src/utils/welcome";
import * as serviceWorkerRegistration from "../services/pwa/serviceWorkerRegistration";

// import { generatePathsArray } from "../scripts/sitemap/flattenArray";
// generatePathsArray();

import "src/translations/i18n";

import "@scss/utils.scss";
import "@scss/base.scss";
import "@scss/lib.scss";
import "@scss/responsive.scss";

welcome();

serviceWorkerRegistration.register();

const App = () => {
  return <Router />;
};

export default App;
