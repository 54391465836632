import { AxiosError } from "axios";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IContext } from "@src/types/IContext.types";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";

import { UserDto } from "@src/services/api/api.dtos";
import { getUser } from "@src/services/api/methods";
import { sleep } from "@src/utils/sleep";
import { PATHS } from "@src/router/paths";

interface ContextValue {
  user: UserDto | null;
  authCode: string | null;
  authStatus: AuthStatus;
  authError: AxiosError | null;
  manualAuthorize: (authCode: string) => Promise<void>;
  changeAuthStatus: (status: AuthStatus) => void;
  logout: () => void;
  restoreDefaultAuthState: () => void;
}

export enum STORAGE_AUTH_KEYS {
  CODE = "auth_code",
  USER = "user"
}

export type AuthStatus = "logging" | "loggedIn" | "loggedOut" | "error" | "invalidAuthUrl";

export const AUTH_CODE_KEY_PARAM = "auth_code";

export const getUserFromLocalStorage = () => {
  const savedUser = BrowserStorageManager.readLocalStorage<UserDto>(STORAGE_AUTH_KEYS.USER);
  const savedAuthCode = BrowserStorageManager.readLocalStorage<string>(STORAGE_AUTH_KEYS.CODE);

  return { savedUser, savedAuthCode };
};

const AuthContext = React.createContext(null as any);

export const AuthProvider = ({ children }: IContext) => {
  const [user, setUser] = useState<UserDto | null>(null);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [authStatus, setAuthStatus] = useState<AuthStatus>("loggedOut");
  const [authError, setAuthError] = useState<AxiosError | null>(null);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const authCodeParam = searchParams.get(AUTH_CODE_KEY_PARAM);

  const _loginRequest = async (authCodeParam: string) => {
    setAuthStatus("logging");
    setAuthError(null);

    try {
      await sleep(500);
      const res = await getUser(authCodeParam);

      if (res.status === 200) {
        setUser(res.data);
        setAuthCode(res.data?.activationCode);
        setAuthStatus("loggedIn");
      }

      return res;
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        throw e;
      }

      setAuthStatus("error");
      setAuthError(e);
    }
  };

  const _authorize = async () => {
    const { savedUser, savedAuthCode } = getUserFromLocalStorage();

    if (authCodeParam) {
      const res = await _loginRequest(authCodeParam);

      // Obsługa błędu
      if (res?.status === 200) {
        BrowserStorageManager.writeLocalStorage(STORAGE_AUTH_KEYS.USER, res.data);
        BrowserStorageManager.writeLocalStorage(STORAGE_AUTH_KEYS.CODE, res.data.activationCode);
        navigate(PATHS.main.path);
      } else {
        setAuthStatus("invalidAuthUrl");
      }
    } else if (savedAuthCode && savedUser) {
      setAuthStatus("logging");

      if (savedAuthCode === savedUser.activationCode) {
        setAuthCode(savedAuthCode);
        setUser(savedUser);
        setAuthStatus("loggedIn");
      } else {
        logout();
      }
    } else if (!savedAuthCode && savedUser) {
      logout();
    } else if (savedAuthCode && !savedUser) {
      const res = await _loginRequest(savedAuthCode);

      // Obsługa błędu
      if (res?.status === 200) {
        BrowserStorageManager.writeLocalStorage(STORAGE_AUTH_KEYS.USER, res.data);
        return navigate(PATHS.main.path);
      }
    }
  };

  const manualAuthorize = useCallback(async (authCode: string) => {
    const res = await _loginRequest(authCode);

    if (res?.status === 200) {
      BrowserStorageManager.writeLocalStorage(STORAGE_AUTH_KEYS.USER, res.data);
      BrowserStorageManager.writeLocalStorage(STORAGE_AUTH_KEYS.CODE, res.data?.activationCode);
      navigate(PATHS.main.path);
    }
  }, []);

  const changeAuthStatus = useCallback((status: AuthStatus) => setAuthStatus(status), []);

  const logout = useCallback(() => {
    setAuthCode(null);
    setUser(null);
    setAuthError(null);
    setAuthStatus("loggedOut");

    BrowserStorageManager.removeLocalStorageItems([STORAGE_AUTH_KEYS.CODE, STORAGE_AUTH_KEYS.USER]);
    navigate(PATHS.main.path);
  }, []);

  const restoreDefaultAuthState = useCallback(() => {
    setAuthCode(null);
    setUser(null);
    setAuthError(null);
    setAuthStatus("loggedOut");
  }, []);

  useEffect(() => {
    _authorize();
  }, [authCodeParam]);

  const contextValue: ContextValue = {
    user,
    authCode,
    authStatus,
    authError,
    manualAuthorize,
    changeAuthStatus,
    logout,
    restoreDefaultAuthState
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export const useAuth = (): ContextValue => React.useContext(AuthContext);
