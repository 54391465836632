import { SvgIcon } from "@src/types/SvgIcon.types";

const ArrowLeft = ({ width = 8, height = 14, color = "#2C2C2C", className = "" }: SvgIcon) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path d="M7 13L1 7L7 1" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
};

export default ArrowLeft;
