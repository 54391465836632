import React from "react";
import { Helmet } from "react-helmet-async";
import { useTranslation } from "react-i18next";
import classNames from "classnames";

import Navbar from "@src/components/layoutElements/Navbar/Navbar";

import variables from "@scss/theme_color.module.scss";
import "./LayoutWithNavbar.scss";

interface LayoutWithNavbarProps {
  children: React.ReactNode | React.ReactElement;
  title?: string;
  hideNavigate?: boolean;
  onNavigate?(): void;
  withoutPadding?: boolean;
  disableSrcoll?: boolean;
  className?: string;
}

const LayoutWithNavbar = ({
  children,
  title,
  hideNavigate,
  onNavigate,
  withoutPadding = false,
  disableSrcoll = false,
  className
}: LayoutWithNavbarProps) => {
  const { t } = useTranslation();

  const classes = classNames("layout-with-navbar", className);
  const contentClasses = classNames("layout-with-navbar-main", {
    "content-padding": !withoutPadding,
    "disable-scroll": disableSrcoll
  });

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={variables["layout-with-navbar"]} />
      </Helmet>

      <div className={classes}>
        <Navbar title={t(title as any)} hideNavigate={hideNavigate} onNavigate={onNavigate} />
        <div className={contentClasses}>{children}</div>
      </div>
    </>
  );
};

export default LayoutWithNavbar;
