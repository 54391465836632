import { APP_VERSION } from "@config/config";

const WELCOME_MESSAGE = "Welcome to Cert Go2Nft App";

const getRandomRGBNumber = () => Math.floor(Math.random() * 256);

const getRandomColor = () => {
  const r = getRandomRGBNumber();
  const g = getRandomRGBNumber();
  const b = getRandomRGBNumber();

  return [`rgb(${r}, ${g}, ${b})`, `rgb(${255 - r}, ${255 - g}, ${255 - b})`];
};

export const welcome = () => {
  const [color, invertedColor] = getRandomColor();

  const styles = [
    "font-size: 14px",
    // `color: ${color}`,
    // `background-color: ${invertedColor}`,
    `color: rgb(253,152,9)`,
    "border-radius: 8px",
    "padding: 8px"
  ].join(";");

  console.log(`%c ${WELCOME_MESSAGE} - ${APP_VERSION}`, styles);
};
