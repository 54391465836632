import React, { useRef, useEffect, useState } from "react";
import classNames from "classnames";

import { debounce } from "@src/utils/debounce";

import { InfiniteScrollProps } from "./InfiniteScroll.interface";
import { DIRECTION, isTreeScrollable } from "./utils/isScrollable";
import { ThresholdUnits, parseThreshold } from "./utils/threshold";

import DefaultPullingContent from "./utils/DefaultPullingContent";
import DefaultRefreshingContent from "./utils/DefaultRefreshingContent";
import DefaultEndListContent from "./utils/DefaultEndListContent";

import "./InfiniteScroll.scss";

const InfiniteScroll = ({
  isPullable = true,
  canFetchMore = false,
  onRefresh,
  onFetchMore,
  refreshingContent = <DefaultRefreshingContent />,
  pullingContent = <DefaultPullingContent />,
  endListContent = <DefaultEndListContent />,
  children,
  pullDownThreshold = 67,
  maxPullDownDistance = 95,
  fetchMoreThreshold = "30px",
  resistance = 1,
  className = ""
}: InfiniteScrollProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const childrenRef = useRef<HTMLDivElement>(null);
  const pullDownRef = useRef<HTMLDivElement>(null);
  const fetchMoreRef = useRef<HTMLDivElement>(null);

  const [fetchMoreTresholdBreached, setFetchMoreTresholdBreached] = useState(false);
  let pullToRefreshThresholdBreached = false; // don't change to useState

  let isDragging = false;
  let startY = 0;
  let currentY = 0;

  useEffect(() => {
    if (!isPullable || !childrenRef || !childrenRef.current || fetchMoreTresholdBreached) return;
    const childrenEl = childrenRef.current;

    childrenEl.addEventListener("touchstart", onTouchStart, { passive: true });
    childrenEl.addEventListener("mousedown", onTouchStart);
    childrenEl.addEventListener("touchmove", onTouchMove, { passive: false });
    childrenEl.addEventListener("mousemove", onTouchMove);
    childrenEl.addEventListener("scroll", onScrollDebounced);
    childrenEl.addEventListener("touchend", onEnd);
    childrenEl.addEventListener("mouseup", onEnd);
    document.body.addEventListener("mouseleave", onEnd);

    return () => {
      childrenEl.removeEventListener("touchstart", onTouchStart);
      childrenEl.removeEventListener("mousedown", onTouchStart);
      childrenEl.removeEventListener("touchmove", onTouchMove);
      childrenEl.removeEventListener("mousemove", onTouchMove);
      childrenEl.removeEventListener("scroll", onScrollDebounced);
      childrenEl.removeEventListener("touchend", onEnd);
      childrenEl.removeEventListener("mouseup", onEnd);
      document.body.removeEventListener("mouseleave", onEnd);
    };
  }, [isPullable, childrenRef, fetchMoreTresholdBreached, onFetchMore, canFetchMore]);

  const resetSettings = () => {
    if (childrenRef.current) {
      childrenRef.current.style.overflowX = "hidden";
      childrenRef.current.style.overflowY = "auto";
      childrenRef.current.style.transform = `unset`;
    }

    if (pullDownRef.current) {
      pullDownRef.current.style.opacity = "0";
    }

    if (containerRef.current) {
      containerRef.current.classList.remove("infiniteScroll-pullDown-tresholdBreached");
      containerRef.current.classList.remove("infiniteScroll-dragging");
    }

    if (pullToRefreshThresholdBreached) pullToRefreshThresholdBreached = false;
    // if (fetchMoreTresholdBreached) setFetchMoreTresholdBreached(false);
  };

  const checkIsEndOfScrollable = (
    scrollableElement: HTMLElement,
    scrollThreshold: string | number = fetchMoreThreshold
  ) => {
    const clientHeight =
      scrollableElement === document.body || scrollableElement === document.documentElement
        ? window.screen.availHeight
        : scrollableElement.clientHeight;

    const threshold = parseThreshold(scrollThreshold);

    if (threshold.unit === ThresholdUnits.Pixel) {
      return scrollableElement.scrollTop + clientHeight >= scrollableElement.scrollHeight - threshold.value;
    }

    return scrollableElement.scrollTop + clientHeight >= (threshold.value / 100) * scrollableElement.scrollHeight;
  };

  const onTouchStart = (e: MouseEvent | TouchEvent): void => {
    isDragging = false;
    if (e instanceof MouseEvent) {
      startY = e.pageY;
    }
    if (window.TouchEvent && e instanceof TouchEvent) {
      startY = e.touches[0].pageY;
    }
    currentY = startY;
    // Check if element can be scrolled
    if (e.type === "touchstart" && isTreeScrollable(e.target as HTMLElement, DIRECTION.UP)) return;

    // Top non visible so cancel
    if (childrenRef.current!.getBoundingClientRect().top < 0) return;

    isDragging = true;
  };

  const onTouchMove = (e: MouseEvent | TouchEvent): void => {
    if (!isDragging) return;

    if (window.TouchEvent && e instanceof TouchEvent) {
      currentY = e.touches[0].pageY;
    } else {
      currentY = (e as MouseEvent).pageY;
    }

    containerRef.current!.classList.add("infiniteScroll-dragging");

    if (currentY < startY) {
      isDragging = false;
      return;
    }

    if (e.cancelable) {
      e.preventDefault();
    }

    const yDistanceMoved = Math.min((currentY - startY) / resistance, maxPullDownDistance);

    // Limit to trigger refresh has been breached
    if (yDistanceMoved >= pullDownThreshold) {
      isDragging = true;
      pullToRefreshThresholdBreached = true;
      containerRef.current!.classList.remove("infiniteScroll-dragging");
      containerRef.current!.classList.add("infiniteScroll-pullDown-tresholdBreached");
    }

    // maxPullDownDistance breached, stop the animation
    if (yDistanceMoved >= maxPullDownDistance) return;

    pullDownRef.current!.style.opacity = (yDistanceMoved / 65).toString();
    childrenRef.current!.style.overflow = "visible";
    childrenRef.current!.style.transform = `translate(0px, ${yDistanceMoved}px)`;
    pullDownRef.current!.style.visibility = "visible";
  };

  const onScroll = async () => {
    if (fetchMoreTresholdBreached) return;

    if (canFetchMore && checkIsEndOfScrollable(childrenRef.current as HTMLElement) && onFetchMore) {
      setFetchMoreTresholdBreached(true);
      await onFetchMore();
      setFetchMoreTresholdBreached(false);
      resetSettings();
    }
  };

  const onScrollDebounced = debounce(onScroll, 150);

  const onEnd = (): void => {
    isDragging = false;
    startY = 0;
    currentY = 0;

    // Container has not been dragged enough, put it back to it's initial state
    if (!pullToRefreshThresholdBreached) {
      if (pullDownRef.current) pullDownRef.current.style.visibility = "hidden";
      resetSettings();
      return;
    }

    if (childrenRef.current) {
      childrenRef.current.style.overflow = "visible";
      childrenRef.current.style.transform = `translate(0px, ${pullDownThreshold}px)`;
    }
    onRefresh().then(resetSettings).catch(resetSettings);
  };

  const classes = classNames("infiniteScroll", className);

  return (
    <div ref={containerRef} className={classes}>
      <div ref={pullDownRef} className="infiniteScroll-pullDown">
        <div className="infiniteScroll-loader infiniteScroll-pullDown-loading">{refreshingContent}</div>
        <div className="infiniteScroll-pullDown-pullMore">{pullingContent}</div>
      </div>

      <div ref={childrenRef} className="infiniteScroll-children">
        {children}

        <div ref={fetchMoreRef} className="infiniteScroll-fetchMore">
          {fetchMoreTresholdBreached && refreshingContent}
          {!canFetchMore && onFetchMore && !fetchMoreTresholdBreached && endListContent}
        </div>
      </div>
    </div>
  );
};

export default InfiniteScroll;
