import React from "react";

import Stack from "@src/components/arrangement/Stack/Stack";
import Spinner from "@src/components/common/Spinner/Spinner";

import "./SearchProductsLoading.scss";

const SearchProductsLoading = () => {
  return (
    <Stack fullWidth alignItems="center" justifyContent="center" className="certify-search-products-loading">
      <Spinner sizePx={28} />
    </Stack>
  );
};

export default SearchProductsLoading;
