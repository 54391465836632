import { URL_REGEX } from "@src/config/helpers";

export const getStickerCode = (scannedValue: string) => {
  let code = "";
  const isUrl = URL_REGEX.test(scannedValue);

  if (isUrl) code = scannedValue.split("/").slice(-1)[0];
  else code = scannedValue;

  return { isUrl, code };
};
