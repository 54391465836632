import { useTranslation } from "react-i18next";
import { useState } from "react";

import { URL_REGEX } from "@src/config/helpers";
import { useAuth } from "@src/contexts/AuthContext";
import { useToasts } from "@src/contexts/ToastsContext";

import LoadingScreen from "@src/components/common/LoadingScreen/LoadingScreen";
import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import CodesScanner from "@src/components/common/CodesScanner/CodesScanner";
import AuthQrCodeError from "./AuthQrCodeError/AuthQrCodeError";

import "./AuthQrCode.scss";

const getAuthCodeFromQr = (url: string, searchedParam = "auth_code") => {
  if (!URL_REGEX.test(url)) return null;
  return new URL(url).searchParams.get(searchedParam);
};

const AuthQrCode = () => {
  const [scannedCode, setScannedCode] = useState<string | null>(null);

  const { authStatus, manualAuthorize, changeAuthStatus } = useAuth();
  const { showToast, hideToast } = useToasts();

  const { t } = useTranslation("apiResponse");

  const scanAuthCode = (url: string) => {
    if (scannedCode) return;

    const qrCode = getAuthCodeFromQr(url);

    if (qrCode) {
      changeAuthStatus("logging");
      setScannedCode(qrCode);
      manualAuthorize(qrCode);
    } else {
      showToast({
        type: "error",
        message: t("errors.invalidAuthQr"),
        onClick: hadleCloseToast
      });
    }
  };

  const handleTryAgain = () => {
    setScannedCode(null);
    changeAuthStatus("loggedOut");
  };

  const hadleCloseToast = () => {
    handleTryAgain();
    hideToast();
  };

  const showCodesScanner = !scannedCode && authStatus !== "error";

  if (authStatus === "logging") return <LoadingScreen />;

  return (
    <LayoutWithNavbar title="auth:authQrCode.navbar" withoutPadding>
      <div className="auth-qr-code">
        {showCodesScanner && <CodesScanner onResult={scanAuthCode} />}
        {authStatus === "error" && <AuthQrCodeError onTryAgain={handleTryAgain} />}
      </div>
    </LayoutWithNavbar>
  );
};

export default <AuthQrCode />;
