import React from "react";
import { useTranslation } from "react-i18next";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@components/dataEntry/Button/Button";

import "./OfflineNetwork.scss";

const OfflineNetwork = () => {
  const { t } = useTranslation(["common", "layout"]);

  const reloadPage = () => window.location.reload();

  return (
    <BaseLayout withImage>
      <div className="offline-network">
        <Stack rowGap={16} alignItems="center">
          <p className="p0 error">{t("layout:offlineNetwork.connectionFailed")}</p>
          <img src="/images/status/icon-error.svg" alt="offline network" width={28} height={28} />
          <Button onClick={reloadPage}>{t("common:tryAgain")}</Button>
        </Stack>
      </div>
    </BaseLayout>
  );
};

export default OfflineNetwork;
