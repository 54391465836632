import React from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { CountdownTimerFormat, useCountdownTimer } from "@hooks/useCountdownTimer";
import Dots from "../Dots/Dots";

import "./CountdownTimer.scss";

interface CountdownTimerProps {
  targetDate: Date;
  className?: string;
}

const formatLeftTimeValue = (value: number) => value.toString().padStart(2, "0");

const CountdownTimer = ({ targetDate, className }: CountdownTimerProps) => {
  const { minutes, seconds } = useCountdownTimer(targetDate, CountdownTimerFormat.MINUTES_SECONDS);

  const { t } = useTranslation("common");

  const timeIsUp = minutes ? minutes <= 0 && seconds <= 0 : seconds <= 0;
  const classes = classNames("countdown-timer", className);

  return (
    <div className={classes}>
      {!timeIsUp ? (
        <p>
          <span>{formatLeftTimeValue(minutes || 0)}</span>:<span>{formatLeftTimeValue(seconds)}</span>
        </p>
      ) : (
        <p className="relative mr-0">
          {t("retry")} <Dots />
        </p>
      )}
    </div>
  );
};

export default CountdownTimer;
