import { createContext, useContext } from "react";

export type ErrorBoundaryContextType = {
  didCatch: boolean;
  error: any;
  resetErrorBoundary: (...args: any[]) => void;
};

export const ErrorBoundaryContext = createContext<ErrorBoundaryContextType | null>(null);

export const useErrorBoundaryContext = (): ErrorBoundaryContextType | null => useContext(ErrorBoundaryContext);
