export type Order = "asc" | "desc";

export enum CertStatus {
  in_progress = "in_progress",
  minted = "minted",
  withdrawn = "withdrawn",
  error = "error"
}

export type CertStatusType = keyof typeof CertStatus;

export interface ApiError {
  message: string;
  errorCode: number;
  httpCode: number;
}

export interface CompanyDto {
  id: string;
  name: string;
  email: string;
  avatar: string;
}

export interface UserDto {
  name: string;
  active: boolean;
  expiresAt: number;
  activationCode: string;
  companies: CompanyDto[];
}

export interface StickerDto {
  id: string;
  serialNumber: string;
  displaySerialNumber: string;
  createdAt: string;
  company?: string;
  cert?: {
    product: string;
    sticker: string;
    seller: string;
  };
}

export interface ProductDto {
  id: string;
  title: string;
  description: string;
  url: string;
  tagType: string;
  code: string;
  image: string;
  company: string;
  createdAt?: string;
  updatedAt?: string;
}

export interface CertDto {
  id: string;
  sticker: StickerDto;
  productData: ProductDto;
  status: CertStatusType;
  remoteId: string;
  createdAt: string;
  updatedAt: string;
}
