import { useTranslation } from "react-i18next";

import { useAuth } from "@src/contexts/AuthContext";

import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import "./AuthQrCodeError.scss";

interface AuthQrCodeErrorProps {
  onTryAgain(): void;
}

const AuthQrCodeError = ({ onTryAgain }: AuthQrCodeErrorProps) => {
  const { authError } = useAuth();

  const { t } = useTranslation(["auth", "apiResponse", "common"]);

  const authErrorMessage =
    authError?.response?.status === 401 ? "apiResponse:errors.invalidAuthQrCode" : "apiResponse:errors.base";

  return (
    <div className="auth-qr-code-error">
      <Stack alignItems="center" fullWidth className="mt-4">
        <img src="/images/qr-example.svg" alt="example qr code" width={90} height={90} />

        <Stack alignItems="center" rowGap={24} className="mt-8">
          <p className="p0 error">{t(authErrorMessage as any)}</p>
          <img src="/images/status/icon-error-2.svg" alt="error icon" width={28} height={28} />
          <Button onClick={onTryAgain}>{t("common:tryAgain")}</Button>
        </Stack>
      </Stack>

      <div className="auth-qr-code-info">
        <p className="p0 gray-0">{t("auth:authCodeInfo")}</p>
      </div>
    </div>
  );
};

export default AuthQrCodeError;
