import React from "react";
import { useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";

import { useAuth } from "@src/contexts/AuthContext";
import { PATHS } from "@src/router/paths";

import BaseLayout from "@src/components/layouts/BaseLayout/BaseLayout";
import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import "./AuthUrlError.scss";

const AuthUrlError = () => {
  const navigate = useNavigate();
  const { authError, changeAuthStatus } = useAuth();

  const { t } = useTranslation(["apiResponse", "common"]);

  const goToLogin = () => {
    changeAuthStatus("loggedOut");
    navigate(PATHS.auth.path);
  };

  const authErrorMessage =
    authError?.response?.status === 401 ? "apiResponse:errors.invalidAuthUrl" : "apiResponse:errors.base";

  return (
    <BaseLayout withImage>
      <div className="auth-url-error">
        <Stack rowGap={16} alignItems="center">
          <p className="p0 error text-center">
            <Trans t={t} i18nKey={authErrorMessage} components={{ br: <br /> }} />
          </p>

          <img src="/images/status/icon-error-2.svg" alt="invalid auth url" />

          <Button size="small" fullWidth onClick={goToLogin}>
            {t("common:login")}
          </Button>
        </Stack>
      </div>
    </BaseLayout>
  );
};

export default AuthUrlError;
