import React from "react";
import { Helmet } from "react-helmet-async";
import classNames from "classnames";

import DashboardHeader from "@src/components/layoutElements/DashboardHeader/DashboardHeader";

import variables from "@scss/theme_color.module.scss";
import "./DashboardLayout.scss";

interface DashboardLayoutProps {
  children: React.ReactElement | React.ReactNode;
  disableSrcoll?: boolean;
  className?: string;
}

const DashboardLayout = ({ children, disableSrcoll = false, className }: DashboardLayoutProps) => {
  const classes = classNames("dashboard-layout", className);
  const mainClasses = classNames("dashboard-layout-main", {
    "disable-scroll": disableSrcoll
  });

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={variables["dashboard-layout"]} />
      </Helmet>

      <div className={classes}>
        <DashboardHeader />

        <div className={mainClasses}>{children}</div>
      </div>
    </>
  );
};

export default DashboardLayout;
