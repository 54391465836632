import React from "react";
import "./Dots.scss";

const dotsStateMachine = (currentDots: number): number => {
  switch (currentDots) {
    case 1:
      return 2;
    case 2:
      return 3;
    case 3:
      return 1;
    default:
      return 3;
  }
};

const Dots = () => {
  const [dotsNumber, setDotsNumber] = React.useState(3);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setDotsNumber(dotsStateMachine(dotsNumber));
    }, 500);

    return () => clearInterval(interval);
  });

  return (
    <span className="dots">
      {Array(dotsNumber)
        .fill(undefined)
        .map((_, index) => (
          <React.Fragment key={index}>.</React.Fragment>
        ))}
    </span>
  );
};

export default Dots;
