import { Navigate, useLocation } from "react-router-dom";

import { getUserFromLocalStorage, useAuth } from "@src/contexts/AuthContext";
import { IChildren } from "@src/types/IChildren.types";
import { PATHS } from "src/router/paths";

import LoadingScreen from "@src/components/common/LoadingScreen/LoadingScreen";
import AuthUrlError from "@src/app/Auth/AuthUrlError/AuthUrlError";

const AuthGuard = ({ children }: IChildren) => {
  const location = useLocation();

  const { authStatus } = useAuth();
  const { savedAuthCode } = getUserFromLocalStorage();

  const authPaths = [PATHS.auth.path, PATHS.authLogin.path, PATHS.authQrCode.path, PATHS.authAccessCode.path] as const;
  const isOnAuthRoutes = authPaths.includes(location.pathname);

  if (authStatus === "logging") {
    return <LoadingScreen />;
  }

  if (!savedAuthCode && authStatus === "loggedOut" && !isOnAuthRoutes) {
    return <Navigate to={PATHS.authLogin.path} />;
  }

  if (authStatus === "loggedIn" && isOnAuthRoutes) {
    return <Navigate to={PATHS.main.path} />;
  }

  if (authStatus === "invalidAuthUrl") {
    return <AuthUrlError />;
  }

  return <>{children}</>;
};

export default AuthGuard;
