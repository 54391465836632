import React, { useEffect, useState } from "react";
import { Result } from "@zxing/library";
import classNames from "classnames";

import { useCamera } from "@src/services/pwa/camera/useCamera";
import { MediaPermissionsError, parseMediaPermissionsError } from "@src/services/pwa/requestMediaPermissions";

import CameraPermissionError from "./components/CameraPermissionError/CameraPermissionError";
import "./CodesScanner.scss";

interface CodesScannerProps {
  paused?: boolean;
  className?: string;
  onResult?(result: string): void;
}

const CodesScanner = ({ paused, className, onResult }: CodesScannerProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [result, setResult] = useState("");
  const [pausedDecode, setPausedDecode] = useState(paused || false);
  const [mediaPermissionsError, setMediaPermissionsError] = useState<MediaPermissionsError | null>(null);

  useEffect(() => {
    if (paused) setPausedDecode(paused);
  }, [paused]);

  const handleResult = (result: Result) => {
    const textResult = result.getText();

    setResult(textResult);
    onResult && onResult(textResult);
  };

  const handleMediaPermissionError = (error: Error) => {
    const permissionError = parseMediaPermissionsError(error);
    setMediaPermissionsError(permissionError);
  };

  const { ref: cameraRef } = useCamera({
    paused: pausedDecode,
    onResult: handleResult,
    onError: handleMediaPermissionError
  });

  const classes = classNames("codes-scanner", className);

  if (mediaPermissionsError) return <CameraPermissionError error={mediaPermissionsError} />;

  return (
    <div className={classes}>
      <video ref={cameraRef} className="camera-video" />

      <div className="camera-overlay" />

      <svg
        width="269"
        height="267"
        viewBox="0 0 269 267"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="camera-indicator"
      >
        <path
          d="M14.392 2.19983L12.4011 2.19983C6.87828 2.19983 2.40112 6.67698 2.40112 12.1998L2.40112 13.791"
          stroke="#FFA800"
          strokeWidth="4"
        />
        <path d="M266.8 13.9909L266.8 12C266.8 6.47716 262.323 2 256.8 2L255.209 2" stroke="#FFA800" strokeWidth="4" />
        <path
          d="M255.009 264.8L257 264.8C262.523 264.8 267 260.323 267 254.8L267 253.209"
          stroke="#FFA800"
          strokeWidth="4"
        />
        <path
          d="M2.40112 253.009L2.40112 255C2.40112 260.523 6.87828 265 12.4011 265L13.9923 265"
          stroke="#FFA800"
          strokeWidth="4"
        />
      </svg>
    </div>
  );
};

export default CodesScanner;
