import { CertStatus, CertStatusType } from "@src/services/api/api.dtos";

export const getVariantCard = (status: CertStatusType) => {
  switch (status) {
    case CertStatus.in_progress:
      return "loading";
    case CertStatus.error:
      return "error";
    default:
      return "base";
  }
};
