import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import { PATHS } from "@src/router/paths";
import { useAuth } from "@src/contexts/AuthContext";

import Stack from "../../arrangement/Stack/Stack";
import Avatar from "../Avatar/Avatar";

import "./DashboardHeader.scss";

interface DashboardHeaderProps {
  className?: string;
}

const DashboardHeader = ({ className }: DashboardHeaderProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation("app");

  const { user } = useAuth();

  const goToSettings = () => navigate(PATHS.settings.path);

  const userName = user?.name || "";
  const userImageUrl = user?.companies[0]?.avatar || "";

  const classes = classNames("dashboard-header", className);

  return (
    <div className={classes}>
      <svg
        width="45"
        height="37"
        viewBox="0 0 45 37"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="left-circles"
      >
        <circle cx="9" cy="13" r="13" fill="white" fillOpacity="0.13" />
        <circle cx="30" cy="22" r="15" fill="white" fillOpacity="0.06" />
      </svg>

      <svg
        width="98"
        height="114"
        viewBox="0 0 98 114"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="right-circle"
      >
        <circle cx="74.5" cy="74.5" r="74.5" fill="white" fillOpacity="0.05" />
      </svg>

      <div className="white-box" />

      <Avatar imgHref={userImageUrl} onClick={goToSettings} className="dashboard-avatar" />

      <Stack rowGap={8}>
        <h1 className="dashboard-title white-0">{t("dashboard.navbar")}</h1>
        <p className="p0 white-0 regular">{userName}</p>
      </Stack>
    </div>
  );
};

export default DashboardHeader;
