import { BrowserName, deviceInfo } from "@src/utils/browser/deviceInfo";

export enum MediaPermissionsErrorType {
  /** (macOS) browser does not have permission to access cam/mic */
  SystemPermissionDenied = "SystemPermissionDenied",
  /** user denied permission for site to access cam/mic */
  UserPermissionDenied = "UserPermissionDenied",
  /** user dismissed permission for site to access cam/mic */
  UserPermissionDismissed = "UserPermissionDismissed",
  /** (Windows) browser does not have permission to access cam/mic OR camera is in use by another application or browser tab */
  CouldNotStartVideoSource = "CouldNotStartVideoSource",
  /** all other errors */
  Generic = "Generic"
}

export enum BrowserPermissionsErrorName {
  NotAllowedError = "NotAllowedError",
  NotReadableError = "NotReadableError",
  NotFoundError = "NotFoundError",
  AbortError = "AbortError"
}

export enum BrowserPermissionsErrorMessage {
  PermissionDeniedBySystem = "Permission denied by system",
  PermissionDenied = "Permission denied",
  PermissionDismissed = "Permission dismissed"
}

export type MediaPermissionsError = {
  type?: MediaPermissionsErrorType;
  name: string;
  message?: string;
};

export const requestMediaPermissions = (constraints?: MediaStreamConstraints) => {
  return new Promise<boolean>((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia(constraints ?? { audio: true, video: true })
      .then((stream: MediaStream) => {
        stream.getTracks().forEach((t) => {
          t.stop();
        });
        resolve(true);
      })
      .catch((err: any) => {
        const error = parseMediaPermissionsError(err);

        reject(error);
      });
  });
};

export const parseMediaPermissionsError = (error: any) => {
  const browserName = deviceInfo.browserInfo.name;

  const { name: errorName, message: errorMessage } = error;
  let errorType: MediaPermissionsErrorType = MediaPermissionsErrorType.Generic;

  if (browserName === BrowserName.Chrome) {
    if (errorName === BrowserPermissionsErrorName.NotAllowedError) {
      if (errorMessage === BrowserPermissionsErrorMessage.PermissionDeniedBySystem) {
        errorType = MediaPermissionsErrorType.SystemPermissionDenied;
      } else if (errorMessage === BrowserPermissionsErrorMessage.PermissionDenied) {
        errorType = MediaPermissionsErrorType.UserPermissionDenied;
      } else if (errorMessage === BrowserPermissionsErrorMessage.PermissionDismissed) {
        errorType = MediaPermissionsErrorType.UserPermissionDenied;
      }
    } else if (errorName === BrowserPermissionsErrorName.NotReadableError) {
      errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
    }
  } else if (browserName === BrowserName.Safari) {
    if (errorName === BrowserPermissionsErrorName.NotAllowedError) {
      errorType = MediaPermissionsErrorType.UserPermissionDenied;
    }
  } else if (browserName === BrowserName.Edge) {
    if (errorName === BrowserPermissionsErrorName.NotAllowedError) {
      errorType = MediaPermissionsErrorType.UserPermissionDenied;
    } else if (errorName === BrowserPermissionsErrorName.NotReadableError) {
      errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
    }
  } else if (browserName === BrowserName.Firefox) {
    if (errorName === BrowserPermissionsErrorName.NotFoundError) {
      errorType = MediaPermissionsErrorType.SystemPermissionDenied;
    } else if (errorName === BrowserPermissionsErrorName.NotReadableError) {
      errorType = MediaPermissionsErrorType.SystemPermissionDenied;
    } else if (errorName === BrowserPermissionsErrorName.NotAllowedError) {
      errorType = MediaPermissionsErrorType.UserPermissionDenied;
    } else if (errorName === BrowserPermissionsErrorName.AbortError) {
      errorType = MediaPermissionsErrorType.CouldNotStartVideoSource;
    }
  }

  return {
    type: errorType,
    name: error.name,
    message: error.message
  };
};
