import React from "react";

import Group from "@src/components/arrangement/Group/Group";
import Spinner from "@src/components/common/Spinner/Spinner";

const DefaultRefreshingContent = () => {
  return (
    <Group justifyContent="center" fullWidth>
      <Spinner />
    </Group>
  );
};

export default DefaultRefreshingContent;
