import { CertDto } from "../api/api.dtos";

export enum SocketEvents {
  CONNECT = "connect",
  DISCONNECT = "disconnect",
  ERROR = "error",
  CERT_ADDED = "certAdded",
  CERT_STATUS_CHANGED = "certStatusChanged",
  CERT_DELETED = "certDeleted"
}

export type SocketEvent = CertSocketEvent;

export interface CertSocketEvent {
  type: CertSocketEvent;
  data: CertEventData;
}

export interface CertEventData {
  cert: CertDto;
  timestamp: number;
}
