import React from "react";

import { useToasts } from "@src/contexts/ToastsContext";

import Portal from "../Portal/Portal";
import Backdrop from "../Backdrop/Backdrop";
import { BaseToast, ErrorToast, LoadingToast } from "./ToastVariants";

const ToastsPanel = () => {
  const { toasts, hideToast } = useToasts();

  const toastRender = () => {
    if (!toasts.length) return;

    const { id, type, message, onClick } = toasts[0];

    const handleClick = () => {
      onClick && onClick();
      onClick && hideToast();
    };

    switch (type) {
      case "base":
        return (
          <Backdrop open onClick={handleClick}>
            <BaseToast id={id} message={message} onClick={handleClick} />
          </Backdrop>
        );
      case "loading":
        return (
          <Backdrop open onClick={handleClick}>
            <LoadingToast id={id} message={message} onClick={onClick && handleClick} />
          </Backdrop>
        );
      case "error":
        return (
          <Backdrop open onClick={handleClick}>
            <ErrorToast id={id} message={message} onClick={handleClick} />
          </Backdrop>
        );

      default:
        return (
          <Backdrop open onClick={handleClick}>
            <BaseToast id={id} message={message} onClick={handleClick} />;
          </Backdrop>
        );
    }
  };

  if (!toasts.length) return <></>;

  return <Portal>{toastRender()}</Portal>;
};

export default ToastsPanel;
