import React, { useState } from "react";

import { useCertsData } from "@src/contexts/CertsDataContext";
import { sleep } from "@src/utils/sleep";

import Stack from "@src/components/arrangement/Stack/Stack";
import InfiniteScroll from "@src/components/common/InfiniteScroll/InfiniteScroll";
import CertsProductCard from "./CertsProductCard/CertsProductCard";

import "./CertsList.scss";

const CertsList = () => {
  const [canFetchMore, setCanFetchMore] = useState<boolean>(true);
  const [nextPage, setNextPage] = useState(1);

  const { certs, fetchCertsList, fetchMoreCertsList } = useCertsData();

  const handleFetchCertsList = async () => {
    setCanFetchMore(true);
    setNextPage(1);
    await fetchCertsList();
  };

  const handleFetchMore = async () => {
    if (!canFetchMore) return;

    await sleep(500);
    await fetchMoreCertsList(nextPage).then((res) => {
      if (res?.status === 200) {
        setNextPage((prevState) => prevState + 1);
      }

      if (res?.data && res?.data.length <= 0) {
        setCanFetchMore(false);
      }
    });
  };

  return (
    <InfiniteScroll onRefresh={handleFetchCertsList} canFetchMore={canFetchMore} onFetchMore={handleFetchMore}>
      <Stack rowGap={12} fullWidth className="certs-list">
        {certs.map(({ id, status, productData, createdAt }) => {
          return (
            <CertsProductCard
              key={id}
              id={id}
              status={status}
              title={productData.title}
              imageId={productData.image}
              productCode={productData.code}
              createdAt={createdAt}
            />
          );
        })}
      </Stack>
    </InfiniteScroll>
  );
};

export default CertsList;
