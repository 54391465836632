import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { CertDto } from "@src/services/api/api.dtos";

import { useCertsData } from "@src/contexts/CertsDataContext";

import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Stack from "@src/components/arrangement/Stack/Stack";
import BaseProductCard from "@src/components/common/BaseProductCard/BaseProductCard";

import CertStatusCard from "./components/CertStatusCard/CertStatusCard";
import "./CertDetails.scss";

const CertDetails = () => {
  const [certDetials, setCertDetials] = useState<CertDto | null>(null);

  const { certId } = useParams();

  const { certs, status, getCertById } = useCertsData();

  useEffect(() => {
    if (certId && status === "success") {
      const cert = getCertById(certId);
      setCertDetials(cert);
    }
  }, [certId, certs, status]);

  return (
    <div className="cert-details">
      <Stack rowGap={16}>
        {certDetials && (
          <>
            <BaseProductCard product={certDetials.productData} />
            <CertStatusCard cert={certDetials} />
          </>
        )}
      </Stack>
    </div>
  );
};

export default (
  <LayoutWithNavbar title="app:certDetails.navbar">
    <CertDetails />
  </LayoutWithNavbar>
);
