import authEn from "@locales/en/auth.json";
import appEn from "@locales/en/app.json";
import apiResponseEn from "@locales/en/api_response.json";
import notificationsEn from "@locales/en/notifications.json";
import commonEn from "@locales/en/common.json";
import layoutEn from "@locales/en/layout.json";

import authPl from "@locales/pl/auth.json";
import appPl from "@locales/pl/app.json";
import apiResponsePl from "@locales/pl/api_response.json";
import notificationsPl from "@locales/pl/notifications.json";
import commonPl from "@locales/pl/common.json";
import layoutPl from "@locales/pl/layout.json";

export const resources = {
  en: {
    auth: authEn,
    app: appEn,
    apiResponse: apiResponseEn,
    notifications: notificationsEn,
    common: commonEn,
    layout: layoutEn
  },
  pl: {
    auth: authPl,
    app: appPl,
    apiResponse: apiResponsePl,
    notifications: notificationsPl,
    common: commonPl,
    layout: layoutPl
  }
} as const;
