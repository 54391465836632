import { io, Socket } from "socket.io-client";
import React, { useEffect, useRef } from "react";

import { IContext } from "@src/types/IContext.types";
import { GO2NFT_API_URL } from "@src/config/config";
import { AUTH_CODE_QUERY, STORAGE_AUTH_KEYS } from "@src/config/helpers";
import { BrowserStorageManager } from "@src/utils/browser/BrowserStorageManager";

interface ContextValue {
  socket: Socket;
}

const SocketContext = React.createContext(null as any);

export const SocketProvider = ({ children }: IContext) => {
  const authCode = BrowserStorageManager.readLocalStorage<string>(STORAGE_AUTH_KEYS.CODE);

  const socket = useRef(
    io(GO2NFT_API_URL, {
      path: "/ws",
      query: {
        [AUTH_CODE_QUERY]: authCode!
      },
      transports: ["websocket"]
    })
  );

  useEffect(() => {
    socket.current.on("error", (msg: string) => {
      // eslint-disable-next-line no-console
      console.error("SocketIO: Error", msg);
    });

    return () => {
      if (socket && socket.current) {
        socket.current.removeAllListeners();
        socket.current.close();
      }
    };
  }, []);

  const contextValue: ContextValue = {
    socket: socket.current
  };

  return <SocketContext.Provider value={contextValue}>{children}</SocketContext.Provider>;
};

export const useSocket = (): ContextValue => React.useContext(SocketContext);
