import React, { useEffect } from "react";

import NotificationsPanel from "@src/components/overlay/NotificationsPanel/NotificationsPanel";

import { IContext } from "src/types/IContext.types";

type NotificationActivityState = "starting" | "idle" | "stopping";

export interface StartToastArguments {
  id?: string;
  message: string;
  duration?: number;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
  onClose?(): void;
}

export interface NotificationState {
  id: string;
  activityState: NotificationActivityState;
  message: string;
  duration?: number;
  onClick?: (() => void) | ((event: React.MouseEvent<HTMLElement>) => void);
  onClose?(): void;
  active: boolean;
}

interface ContextValue {
  notifications: NotificationState[];
  activeNotificationId: string;
  notify: (args: StartToastArguments) => void;
  hideNotification: (id: string) => void;
  hideActiveNotification: () => void;
}

const STARTING_TOAST_MS = 500;
const STOPPING_TOAST_MS = 500;
const MAX_TOASTS_NUMBER = 3;

const NotificationsContext = React.createContext(null as any);

export const NotificationsProvider = ({ children }: IContext) => {
  const [notifications, setNotifications] = React.useState<NotificationState[]>([]);

  const _addToQueue = (notification: NotificationState) => {
    if (notifications.length >= MAX_TOASTS_NUMBER) {
      return;
    }

    setNotifications((prev) => [...prev, notification]);
  };

  const _updateToastById = (id: string, payload: Partial<NotificationState>) => {
    setNotifications((prev) => {
      if (prev.length === 0) {
        return prev;
      } else {
        return prev.map((prevNotification) => {
          if (prevNotification.id !== id) {
            return prevNotification;
          } else {
            return {
              ...prevNotification,
              ...payload
            };
          }
        });
      }
    });
  };

  const _processToast = (id: string) => {
    const notificationById = notifications.find((notification) => notification.id === id);
    const isNotificationPresent = !!notificationById;
    if (!isNotificationPresent) {
      return;
    }

    _updateToastById(id, { activityState: "idle" });

    const toastDuration = notificationById.duration;

    if ((typeof toastDuration === "number" && toastDuration <= 0) || !toastDuration) {
      return;
    }

    const timer = setTimeout(() => {
      _stopToast(id);
      clearTimeout(timer);
    }, toastDuration);
  };

  const _stopToast = (id: string) => {
    const notificationById = notifications.find((notification) => notification.id === id);

    if (!notificationById) {
      return;
    }

    _updateToastById(id, { activityState: "stopping" });

    const timer = setTimeout(() => {
      _removeFromQueue(id);
      clearTimeout(timer);
    }, STOPPING_TOAST_MS);
  };

  const _removeFromQueue = (id: string) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const notify = ({ id, duration, message, onClick, onClose }: StartToastArguments) => {
    const _random = Math.random().toString(16).slice(2);
    const defaultId = id || `${_random}-${Date.now()}`;

    const notification: NotificationState = {
      id: defaultId,
      activityState: "starting",
      message,
      duration,
      onClick,
      onClose,
      active: false
    };

    _addToQueue(notification);
  };

  const hideNotification = (id: string) => {
    _stopToast(id);
  };

  const activeNotificationId = notifications.find((notification) => notification.active)?.id || "-1";

  const hideActiveNotification = () => {
    hideNotification(activeNotificationId);
  };

  useEffect(() => {
    const isAnyNotifications = notifications.length > 0;
    if (!isAnyNotifications) {
      return;
    }

    const isAnyActive = notifications.some((notification) => notification.active);

    if (!isAnyActive) {
      return setNotifications((prev) => {
        if (prev.length === 0) {
          return prev;
        } else {
          return prev.map((notification, index) => {
            if (index === 0) {
              return { ...notification, active: true };
            } else {
              return notification;
            }
          });
        }
      });
    }
  }, [notifications]);

  useEffect(() => {
    const activeNotification = notifications.find((notification) => notification.active);

    if (!activeNotification) {
      return;
    }

    if (activeNotification.activityState === "starting") {
      const timer = setTimeout(() => {
        _processToast(activeNotification!.id);
        clearTimeout(timer);
      }, STARTING_TOAST_MS);
    }
  }, [notifications]);

  return (
    <NotificationsContext.Provider
      value={{
        notifications,
        activeNotificationId,
        notify,
        hideNotification,
        hideActiveNotification
      }}
    >
      {children}
      <NotificationsPanel />
    </NotificationsContext.Provider>
  );
};

export const useNotifications = (): ContextValue => React.useContext(NotificationsContext);
