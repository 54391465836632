import React from "react";

const checkOnlineStatus = async () => {
  const controller = new AbortController();
  const { signal } = controller;

  if (!navigator.onLine) return navigator.onLine;

  const request = new URL(self.location.origin);
  request.searchParams.set("rand", Date.now().toString());
  //
  try {
    const response = await fetch(request.toString(), {
      method: "HEAD",
      signal
    });

    return response.ok;
  } catch (error) {
    console.error(error);

    controller.abort();
  }

  return false;
};

const useNetworkStatus = () => {
  const [isOnline, setNetworkStatus] = React.useState<boolean>(window.navigator.onLine);

  const updateNetworkStatus = () => setNetworkStatus(window.navigator.onLine);

  const checkStatus = async () => {
    const online = await checkOnlineStatus();
    setNetworkStatus(online);
  };

  React.useEffect(() => {
    window.addEventListener("offline", updateNetworkStatus);
    window.addEventListener("online", checkStatus);

    return () => {
      window.removeEventListener("offline", updateNetworkStatus);
      window.removeEventListener("online", checkStatus);
    };
  });

  return isOnline;
};

export default useNetworkStatus;
