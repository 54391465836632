import React from "react";

import { TIME_TO_MINT } from "@src/config/config";
import LayoutWithNavbar from "@src/components/layouts/LayoutWithNavbar/LayoutWithNavbar";
import Button from "@src/components/dataEntry/Button/Button";
import CountdownTimer from "@src/components/common/CountdownTimer/CountdownTimer";

import "./Debug.scss";

const Debug = () => {
  const targetDate = new Date(new Date().getTime() + TIME_TO_MINT * 80);

  return (
    <div className="debug">
      Sales mobile app
      <Button>Click me!</Button>
      <CountdownTimer targetDate={targetDate} />
    </div>
  );
};

export default (
  <LayoutWithNavbar title="Wprowadź kod">
    <Debug />
  </LayoutWithNavbar>
);
