import React from "react";
import { useTranslation } from "react-i18next";

import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions
} from "@src/services/pwa/requestMediaPermissions";
import isMobile from "@src/utils/browser/isMobile";

import Stack from "@src/components/arrangement/Stack/Stack";
import Button from "@src/components/dataEntry/Button/Button";

import "./CameraPermissionError.scss";

interface CameraPermissionErrorProps {
  error: MediaPermissionsError;
}

const CameraPermissionError = ({ error }: CameraPermissionErrorProps) => {
  const { t } = useTranslation("layout");

  const handleRequestMediaPermissions = () => {
    // eslint-disable-next-line no-console
    requestMediaPermissions().catch((e) => console.error(e));
  };

  const isAppPwaMode = isMobile.standaloneMode();
  const showRetryBtn = error.type === MediaPermissionsErrorType.UserPermissionDismissed;

  return (
    <Stack rowGap={32} alignItems="center" justifyContent="center" fullWidth className="perminnsions-error">
      <div className="perminnsions-error-icon-box">
        <svg
          width="269"
          height="267"
          viewBox="0 0 269 267"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="error-camera-icon-bg"
        >
          <path
            d="M14.392 2.19983L12.4011 2.19983C6.87828 2.19983 2.40112 6.67698 2.40112 12.1998L2.40112 13.791"
            stroke="#FFA800"
            strokeWidth="4"
          />
          <path
            d="M266.8 13.9909L266.8 12C266.8 6.47716 262.323 2 256.8 2L255.209 2"
            stroke="#FFA800"
            strokeWidth="4"
          />
          <path
            d="M255.009 264.8L257 264.8C262.523 264.8 267 260.323 267 254.8L267 253.209"
            stroke="#FFA800"
            strokeWidth="4"
          />
          <path
            d="M2.40112 253.009L2.40112 255C2.40112 260.523 6.87828 265 12.4011 265L13.9923 265"
            stroke="#FFA800"
            strokeWidth="4"
          />
        </svg>

        <img
          src="/images/icons/icon-no-access-camera.svg"
          alt="no access camera"
          width={62}
          height={59}
          className="error-camera-icon"
        />
      </div>

      <Stack rowGap={8} className="mt-3 text-center error-message">
        <p className="p0 error">{t("permissions.noAccessCamera.base")}</p>

        {isAppPwaMode && <p className="p0 error">{t("permissions.noAccessCamera.pwaInfo")}</p>}
      </Stack>

      {showRetryBtn && <Button onClick={handleRequestMediaPermissions}>{t("permissions.noAccessCamera.retry")}</Button>}
    </Stack>
  );
};

export default CameraPermissionError;
