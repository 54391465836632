import React from "react";
import { Helmet } from "react-helmet-async";
import classNames from "classnames";

import variables from "@scss/theme_color.module.scss";
import "./BaseLayout.scss";

interface BaseLayoutProps {
  withImage?: boolean;
  className?: string;
  disableHelment?: boolean;
  children: React.ReactElement | React.ReactNode;
}

const BaseLayout = ({ withImage = false, className, disableHelment = false, children }: BaseLayoutProps) => {
  const classes = classNames("base-layout", className);

  return (
    <>
      {!disableHelment && (
        <Helmet>
          <meta name="theme-color" content={variables["base-layout"]} />
        </Helmet>
      )}

      <div className={classes}>
        <img src="/images/go2nft-brand-logo.svg" alt="Go2nft logo" width={130} height={81} />
        {withImage && <img src="/images/nft-token-scan.svg" alt="scan nft token" width={150} height={110} />}

        <>{children}</>
      </div>
    </>
  );
};

export default BaseLayout;
