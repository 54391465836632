import React from "react";
import classNames from "classnames";

import "./Avatar.scss";

interface AvatarProps {
  imgHref?: string;
  alt?: string;
  sizePx?: number;
  className?: string;
  onClick?(): void;
}

const Avatar = ({ imgHref, alt = "avatar", sizePx = 35, className, onClick }: AvatarProps) => {
  const classes = classNames("avatar", className);

  const injectedVariables = {
    "--circle-size": `${sizePx}px`
  };

  return (
    <div role="button" onClick={onClick} style={injectedVariables as React.CSSProperties} className={classes}>
      {imgHref ? (
        <img src={imgHref} alt={alt} className="avatar-image" width={sizePx} height={sizePx} />
      ) : (
        <div className="avatar-placeholder" />
      )}
    </div>
  );
};

export default Avatar;
