import React from "react";
import classNames from "classnames";

import { HANDLE_CLICK_TIMEOUT } from "@src/config/config";

import "./ActionIcon.scss";

interface ActionIconProps {
  children: React.ReactNode;
  id?: string;
  name?: string;
  disabled?: boolean;
  className?: string;
  onClick?(): void;
}

const ActionIcon = ({ children, id, name, disabled, className, onClick }: ActionIconProps) => {
  const handleClick = () => {
    if (!onClick) return;

    setTimeout(() => {
      onClick();
    }, HANDLE_CLICK_TIMEOUT);
  };

  const classes = classNames("action-icon", className);

  return (
    <button id={id} name={name} disabled={disabled} onClick={handleClick} className={classes}>
      <div className="content">{children}</div>
    </button>
  );
};

export default ActionIcon;
