import { useEffect } from "react";

import { useSocket } from "./SocketContext";
import { SocketEvents } from "./Socket.types";

const useSocketSubscribe = (eventName: SocketEvents, eventHandler: (...args: any[]) => void) => {
  const { socket } = useSocket();

  useEffect(() => {
    // console.log("SocketIO: adding listener", eventName);
    socket.on(eventName, eventHandler);

    return () => {
      // console.log("SocketIO: removing listener", eventName);
      socket.off(eventName, eventHandler);
    };
  }, [eventName, socket, eventHandler]);
};

export default useSocketSubscribe;
